import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrameF2 from '../components/FrameF2'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

import Estados from './Estados'

const swal = withReactContent(Swal)

function Lote() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [comboSit, setComboSit] = useState([{ value: '', display: '' }])
  const [enableEdit, setEnableEdit] = useState('N')
  const auth = JSON.parse(localStorage['authtarento'] || JSON.stringify({ authenticated: false, user: 0 }))
  const configTela = {
      path: '/estados',
      component: Estados,
      protected: true,
      exact: true,
      checkPermission: true,
      register: false,
      name: 'Estados'
  }

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      auth.grupo == 1 ? setEnableEdit('S') : setEnableEdit('N')

      setComboSit(await api.getComboData('situacao_lote'))

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/lote?pk_lot=' + query.codigo).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])
            setData(r.data[0])
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'fk_slo'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'fk_slo') fieldName = 'Situação'
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/lote/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/lote/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'CNPJ':
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Cidade</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='100'
            value={data.pk_lot || ''}
            onChange={handleChange}
            name="pk_lot"
            id="pk_lot"
            color="#333333"
            label="Código Lote"
            variant="outlined"
            size="small"
            disabled
            style={{ width: 120 }}
          />
            <FrTextInput
              maxLength='10'
              value={data.nomecli || ''}
              onChange={handleChange}
              name="nomecli"
              id="nomecli"
              color="#333333"
              label="Local Cliente"
              variant="outlined"
              size="small"
              disabled
              style={{ width: 400 }}
            />
          <FrSelect
            value={data.fk_slo || ''}
            onChange={handleChange}
            name="fk_slo"
            id="fk_slo"
            required
            label='Situação'
            data={comboSit}
            style={{ width: 250 }}
          />

          {/* <FrameF2 tela={Estados} name="Estado" codeName="fk_est" handleChangeCode={handleChange} open={false}/> */}
        </form>
        <div className="regButtonsDiv">
          {(enableEdit === 'S') ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{(enableEdit === 'S') ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Lote;
