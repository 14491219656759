import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { makeStyles, withStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Nav from 'react-bootstrap/Nav'
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from 'react-icons-kit'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Swal from 'sweetalert2'
import Checkbox from '@material-ui/core/Checkbox';
import withReactContent from 'sweetalert2-react-content'
import { ic_gavel } from 'react-icons-kit/md/ic_gavel'
import { ic_thumbs_up_down } from 'react-icons-kit/md/ic_thumbs_up_down'
import { ic_work } from 'react-icons-kit/md/ic_work'
import TableDetail from '../TableDetail'
import { box } from 'react-icons-kit/iconic/box'
import { iosBox } from 'react-icons-kit/ionicons/iosBox'
import api from '../../services/api'
import { filePdf } from 'react-icons-kit/icomoon/filePdf'
import { fileExcel } from 'react-icons-kit/icomoon/fileExcel'
import Dialog from '@material-ui/core/Dialog';
import { MDBContainer, MDBIframe } from "mdbreact";
import Tooltip from '@material-ui/core/Tooltip';

import './index.css';

const icons = [
  { fk_sne: 1, icon: (<Icon size={32} style={{ color: '#2A9D8F' }} icon={ic_thumbs_up_down} />) },
  { fk_sne: 2, icon: (<Icon size={32} style={{ color: '#E9C46A' }} icon={ic_work} />) },
  { fk_sne: 3, icon: (<Icon size={32} style={{ color: '#EF233C' }} icon={ic_gavel} />) },
]

const swal = withReactContent(Swal)

const theme = createTheme({
  palette: {
    primary: { main: '#333333' },
  },
}, ptBR);

const FrCheckBox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const useStyles = makeStyles(theme => ({
  root: {
    "& .Mui-selected": {
      backgroundColor: 'rgba(133, 130, 130, 0.5)',

    },
    "& .Mui-selected:hover": {
      backgroundColor: 'rgba(133, 130, 130, 0.8)',

    },
    width: '100%',
  },
  row:{
    backgroundColor: 'rgba(133, 130, 130, 0.9)',
  },

  container: {
    
    borderRadius:'4px',
    maxHeight: ({ frame, relacionados }) => relacionados ? 'none' : (frame ? '40vh' : '58vh'),
    minHeight: ({ frame, relacionados }) => relacionados ? 'none' : (frame ? '40vh' : '58vh'),
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



const StyledTableCell = withStyles((theme) => ({

  head: {
    backgroundColor: '#333333',
    color: theme.palette.common.white,

  },
  body: {
    fontSize: 12,
  },
}))(TableCell);



export default function FrTable(props) {
  const classes = useStyles({ frame: props.frame, relacionados: props.relacionados });
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
   
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.frame ? 25 : 10);
  const [data, setData] = useState(props.data)
  const [columns, setColumns] = useState(props.columns)
  const [order, setOrder] = React.useState(props.startOrder || 'asc');
  const [orderBy, setOrderBy] = React.useState(props.startOrder || props.columns[0].id);
  const [selected, setSelected] = React.useState([]);
  const [opened, setOpened] = React.useState([]);
  const [width, setWidth] = React.useState(props.width || 100);
  const [showModal, setShowModal] = React.useState(false);
  const [arquivo, setArquivo] = React.useState('');

  useEffect(() => {
    setData(props.data)
    setPage(0)
  }, [props.data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.onChangeCheckbox) props.onChangeCheckbox(selected)
  }, [selected])

  const deleteRecord = (e, id, table) =>{
    e.preventDefault()
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja excluir PERMANENTEMENTE o registro selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        console.log(id)
        api.post('/' + table + '/delete/' + id).then(r => {
          // console.log(r.data)
          if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {
            swal.fire({
              title: 'Registro Excluído',
              text: "O registro foi excluído com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi excluído! " + r.data,
              icon: 'warning',
            })
          }
        }).catch(e => {
          console.log(e.response.data)
          if (e.response?.data?.sqlMessage && e.response?.data?.sqlMessage.indexOf('Cannot delete or update a parent row: a foreign key constraint fails') >= 0) {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi excluído pois possui outros registros vinculados! ",
              icon: 'warning',
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "Houve um problema na exclusão do registro!",
              icon: 'warning',
            })
          }
        })
      }
    })
  }


  function handleDelete(e, id, table) {
    e.preventDefault()
    if(table == 'caixas'){
      api.get('/caixa/temLeitura?id_caixa='+id).then(r => {
        console.log(r.data[0])
        if (!r.data[0]){
          console.log(table)
          console.log('entrou else data')
          deleteRecord(e, id, table)
        }else {
          swal.fire({
            title: 'ATENÇÃO',
            text: "Acionamento com Leitura Registrada!",
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'})
         }
      })
    } else {
      console.log('entrou else tabela')
      deleteRecord(e, id, table)
    }
    
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n[props.codeName]);
      setSelected(newSelecteds);
      if (props.setSelected)
        props.setSelected(newSelecteds)

      return;
    }
    setSelected([]);
    if (props.setSelected)
      props.setSelected([])

  };

  

  const handleClick = (event, name, row) => {
    console.log(name)
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    console.log(row)
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    if (props.setSelected)
      props.setSelected(newSelected)
  };

  async function handleColor(index, codigo) {
    console.log(codigo.ativo)
    api.get()
    await api.get('/caixa/sufixo', { params: {
      pk: codigo ? codigo : null,
     
    } }).then(r => {

      
    if ((r.data[0].ativo == 'N') && (props.regName =='caixas')){

      return document.getElementById("tableRow").style.setProperty('background-color', 'red');
    }
    })
    
    
  }

  async function handleClickEdit  (event, codigo) {
    await api.get('/caixa/sufixo', { params: {
      pk: codigo ? codigo : null,
     
    } }).then(r => {
      console.log(codigo)
      console.log('dataSufixo')
      console.log(r.data)
      if ((r.data[0].sufixo == 'A') && (props.regName == 'caixas')){
        console.log('entrou if')
        window.location.href="/tarento/" + props.regName + "/registro?codigo=" + codigo
        // window.location.replace('/tarento/' + props.regName+'/registro?codigo='+codigo)
      }
              // setData(r.data)
    })
    
  }
  

  const handleClickDetail = (event, name) => {
    console.log(name)
    const selectedIndex = opened.indexOf(name);
    let newOpened = [];

    if (selectedIndex === -1) {
      newOpened = newOpened.concat(opened, name);
    } else if (selectedIndex === 0) {
      newOpened = newOpened.concat(opened.slice(1));
    } else if (selectedIndex === opened.length - 1) {
      newOpened = newOpened.concat(opened.slice(0, -1));
    } else if (selectedIndex > 0) {
      newOpened = newOpened.concat(
        opened.slice(0, selectedIndex),
        opened.slice(selectedIndex + 1),
      );
    }

    setOpened(newOpened);
  }
  function handleClickAcionamento () {
    localStorage['lock_cli'] =  JSON.stringify({ lock_cli: 'S' });
  }

  

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isOpened = (name) => opened.indexOf(name) !== -1;

  const escondeModal = () => setShowModal(false);

  const listaPrecosPdf = async (e, id) => {
    e.preventDefault();

    setArquivo(api.backendUrl + '/api/cliente_produto/listaPrecosPdf?fk_cli=' + id)
    setShowModal(true)
  }

  const listaPrecosExcel = async (e, id) => {
    e.preventDefault();
    window.location.href = api.backendUrl + '/api/cliente_produto/listaPrecosExcel?fk_cli=' + id
  }

  

  return (
    <ThemeProvider theme={theme}>

      <div>
        <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv">
              <MDBContainer className="text-center">
                <MDBIframe src={arquivo} />
              </MDBContainer>
              <button onClick={escondeModal}> Sair </button>
            </div>
          </div>
        </Dialog>
      </div>

      {(data.length === 0) ? (
        <div>{props.searched ? 'Registro(s) não encontrado(s)!' : ''}</div>
      ) : (
       

        <Paper className={classes.root} component={props.regName=='leituras' ? 'table': 'div'}>
          
          <TableContainer className={classes.container} component={props.regName=='leituras' ? 'tbody': 'div'}>
            <Table stickyHeader aria-label="sticky table" size={((props.frame || props.readOnly) ? "small" : "medium")}>
              <TableHead>
                <TableRow>
                  {((!props.readOnly || (props.regName === 'leituras')) && (!props.escondeCheck)) ? (
                    <StyledTableCell padding="checkbox">
                      <FrCheckBox
                        indeterminate={selected.length > 0 && selected.length < data.length}
                        checked={data.length > 0 && selected.length === data.length}
                        onChange={handleSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                      />
                    </StyledTableCell>) : (<></>)}
                    {((props.regName === 'leituras')) ? (
                      <StyledTableCell padding='checkbox'>
                  
                        </StyledTableCell>) : (<></>)}

                  {props.detail ? <StyledTableCell style={{ minWidth: 30, width: 30 }} /> : (<></>)}
                  {(!props.readOnly) ? (
                    <StyledTableCell
                      style={{ minWidth: width, width: width }}
                    >
                      Opções
                    </StyledTableCell>) : (<></>)}
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={createSortHandler((column.order) ? column.order : column.id)}
                        // className="labelOrder"
                        style={(column.order? { backgroundColor: orderBy === column.order ? 'white' : '' }: { backgroundColor: orderBy === column.id ? 'white' : '' })}
                      >
                        {column.label}
                      </TableSortLabel>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody > 
                {stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const isItemSelected = isSelected(row[props.codeName]);
                  const isItemOpened = isOpened(row[props.codeName]);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  if (props.detail) {
                    return (
                      <>
                      
                    
                        <TableRow  aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>
                          {(!props.readOnly || (props.regName === 'leituras')) ? (
                              <TableCell padding="checkbox" onClick={(event) => handleClick(event, row[props.codeName], row)} >
                                <FrCheckBox
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />

                              
                                {/* to={"/tarento/" + props.regName + "/registro?codigo=" + row[props.codeName]} */}

                              </TableCell>

                            ) : (<></>)}
                          {(!props.readOnly || (props.regName === 'leituras')) && props.enableEdit ? (
                            <TableCell>
                                <Tooltip title="Editar">
                                  <Link to={"/tarento/lote/registro?codigo=" + row[props.codeName]}>
                                    <Icon className="tableEdit" icon={ic_mode_edit} size={22} />
                                  </Link>                                
                                </Tooltip>
                              </TableCell>) : (<></>)}  
                          <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={(event) => handleClickDetail(event, row[props.codeName])}>
                              {isItemOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                          {(!props.readOnly) ? ((props.frame) ? (
                            <TableCell>
                              <Icon className="tableEdit" icon={ic_delete} size={22} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                            </TableCell>
                          ) : (
                            <TableCell>                         
                              <Link to={"/tarento/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                <Icon className="tableEdit" icon={ic_mode_edit} size={22} />
                              </Link>
                              <Icon className="tableDelete" icon={ic_delete} size={22} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                            </TableCell>
                          )) : (<></>)}
                          {columns.map((column) => {
                            
                            const value = row[column.id];
                            if (column.image) {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <image src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} />
                                </TableCell>
                              )
                            } else if (column.icon) {
                              let iconComponent = icons.find((item) => item.fk_sne === value)
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {iconComponent.icon}
                                </TableCell>
                              )
                            } else {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number' ? column.format(value) : value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      {/* {(props.regName == 'caixas') && (props.data[index].ativo) == 'N' ? */}
                        
                        <TableRow>
                          <TableDetail isOpen={isItemOpened} detail={row} />
                        </TableRow>
                      </>
                    );

                  } else {
                    return (
                      <>
                      {(row.ativo == 'N') && (props.regName == 'caixas')? 
                      <TableRow className={classes.row} onClick={(event) => { if (!props.readOnly) handleClick(event, row[props.codeName]) }} aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>
                      {((!props.readOnly || (props.regName === 'leituras')) && (!props.escondeCheck)) ? (
                        <TableCell padding="checkbox" onClick={(event) => handleClick(event, row[props.codeName])} >
                          <FrCheckBox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                          
                        </TableCell>
                        
                        
                        ) : (<></>)}
                      {(!props.readOnly) ? (
                        <TableCell >
                          {/* {((props.regName == 'caixas') && (props.data[index].sufixo === 'A')) ?( */}
                            {props.regName == 'caixas' ? 
                            (<div className='row'>
                              <div className='column' >
                                <Tooltip title="Editar">
                                  <Nav.Link style = {{padding: '0px'}} className="navTable" onClick={(event) => handleClickEdit(event, row[props.codeName])}>
                                    <Icon className="tableEdit" icon={ic_mode_edit} size={22} />
                                  </Nav.Link>                                
                              </Tooltip>
                              </div>
                              <div className='column'>
                                <Tooltip title="Excluir">   
                                  <Nav.Link style = {{padding: '0px'}}>
                                    <Icon className="tableDelete" icon={ic_delete} size={20} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                                  </Nav.Link> 
                                </Tooltip> 
                              </div>
                            </div>)
                            :
                            <>
                              <Tooltip title="Editar">
                                <Link to={"/tarento/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                  <Icon className="tableEdit" icon={ic_mode_edit} size={22} />
                                </Link>                                
                              </Tooltip>
                              {/* to={"/tarento/" + props.regName + "/registro?codigo=" + row[props.codeName]} */}
                            </>
                            }
                            
                            

                          

                          {(props.enableDel == 'S')  ? (
                          // <Tooltip title="Excluir">   
                          //   <Link>
                          //     <Icon className="tableDelete" icon={ic_delete} size={20} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                          //   </Link> 
                          // </Tooltip> 
                          <></>
                          )
                            : (<></>)}

                          {(props.page == 'Clientes') ? (
                          <Tooltip title="Editar Produtos">  
                            <Link to={"/tarento/cliente_produto/registro?codigo=" + row[props.codeName]}>
                              <Icon className="tableDelete" icon={box} size={17} />
                            </Link>
                          </Tooltip> 
                          )
                            : (<></>)}

                          {(props.page == 'Clientes') ? (
                          <Tooltip title="Relatório">
                            <Link>
                              <Icon className="tableDelete" icon={filePdf} size={18} onClick={(e) => listaPrecosPdf(e, row[props.codeName])} /> 
                            </Link>
                          </Tooltip>  
                          )
                            : (<></>)}

                          {(props.page == 'Clientes') ? (
                          <Tooltip title="Exportar">
                            <Link>
                              <Icon className="tableDelete" icon={fileExcel} size={18} onClick={(e) => listaPrecosExcel(e, row[props.codeName])} />
                            </Link>
                          </Tooltip>
                          )
                            : (<></>)}

                          {(props.page == 'Clientes') ? (
                          <Tooltip title="Acionamentos">     
                            <Link to={"/tarento/caixas/?fk_cli=" +row[props.codeName]+'&buscar=true'} onClick={handleClickAcionamento}>
                              <Icon className="tableDelete" icon={iosBox} size={27}/>
                            </Link>
                          </Tooltip> 
                          )
                            : (<></>)}  

                        </TableCell>
                      ) : (<></>)}



                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.image) {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <img src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} style={{ maxHeight: '60px' }} />
                            </TableCell>
                          )
                        } else if (column.icon) {
                          let iconComponent = icons.find((item) => item.fk_sne === value)
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {iconComponent.icon}
                            </TableCell>
                          )
                        } else {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>: 
                    <TableRow onClick={(event) => { if (!props.readOnly) handleClick(event, row[props.codeName]) }} aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>
                    {((!props.readOnly || (props.regName === 'leituras')) && (!props.escondeCheck)) ? (
                      <TableCell padding="checkbox" onClick={(event) => handleClick(event, row[props.codeName])} >
                        <FrCheckBox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>) : (<></>)}
                    {(!props.readOnly) ? (
                      <TableCell >
                        {/* {((props.regName == 'caixas') && (props.data[index].sufixo === 'A')) ?( */}
                          {props.regName == 'caixas'? 
                          (<div className='row'>
                            <div className='column' >
                              <Tooltip title="Editar">
                                <Nav.Link style = {{padding: '0px'}} className="navTable" onClick={(event) => handleClickEdit(event, row[props.codeName])}>
                                  <Icon className="tableEdit" icon={ic_mode_edit} size={22} />
                                </Nav.Link>                                
                            </Tooltip>
                            </div>
                            <div className='column'>
                              <Tooltip title="Excluir">   
                                <Nav.Link style = {{padding: '0px'}}>
                                  <Icon className="tableDelete" icon={ic_delete} size={20} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                                </Nav.Link> 
                              </Tooltip> 
                            </div>
                          </div>)
                          :
                          <>
                            <Tooltip title="Editar">
                              <Link to={"/tarento/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                <Icon className="tableEdit" icon={ic_mode_edit} size={22} />
                              </Link>                                
                            </Tooltip>
                            {/* to={"/tarento/" + props.regName + "/registro?codigo=" + row[props.codeName]} */}
                          </>
                          }
                          
                          

                        

                        {(props.enableDel == 'S') && (props.regName != 'caixas') ? (
                        <Tooltip title="Excluir">   
                          <Link>
                            <Icon className="tableDelete" icon={ic_delete} size={20} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                          </Link> 
                        </Tooltip> 
                        )
                          : (<></>)}

                        {(props.page == 'Clientes') ? (
                        <Tooltip title="Editar Produtos">  
                          <Link to={"/tarento/cliente_produto/registro?codigo=" + row[props.codeName]}>
                            <Icon className="tableDelete" icon={box} size={17} />
                          </Link>
                        </Tooltip> 
                        )
                          : (<></>)}

                        {(props.page == 'Clientes') ? (
                        <Tooltip title="Relatório">
                          <Link>
                            <Icon className="tableDelete" icon={filePdf} size={18} onClick={(e) => listaPrecosPdf(e, row[props.codeName])} /> 
                          </Link>
                        </Tooltip>  
                        )
                          : (<></>)}

                        {(props.page == 'Clientes') ? (
                        <Tooltip title="Exportar">
                          <Link>
                            <Icon className="tableDelete" icon={fileExcel} size={18} onClick={(e) => listaPrecosExcel(e, row[props.codeName])} />
                          </Link>
                        </Tooltip>
                        )
                          : (<></>)}

                        {(props.page == 'Clientes') ? (
                        <Tooltip title="Acionamentos">     
                          <Link to={"/tarento/caixas/?fk_cli=" +row[props.codeName]+'&buscar=true'} onClick={handleClickAcionamento}>
                            <Icon className="tableDelete" icon={iosBox} size={27}/>
                          </Link>
                        </Tooltip> 
                        )
                          : (<></>)}  

                      </TableCell>
                    ) : (<></>)}



                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.image) {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <img src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} style={{ maxHeight: '60px' }} />
                          </TableCell>
                        )
                      } else if (column.icon) {
                        let iconComponent = icons.find((item) => item.fk_sne === value)
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {iconComponent.icon}
                          </TableCell>
                        )
                      } else {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow> }
                      
                      </>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={props.regname == 'caixas' ? [10, 25, 100, 150]:[10, 25, 100]}
            component={props.regName=='leituras' ? 'tfoot': 'div'}
            count={data.length}
            rowsPerPage={props.regName == 'caixas' ? 150 : rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            className="no-print"
          />
        </Paper>
      )
      }
    </ThemeProvider >
  );
}
