import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import $ from 'jquery'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrSelect from '../components/FrSelect'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import consultaCep from '../services/consultaCep'
import { paramsToObject, onlyNumbers, validarCNPJ, onBlurDecimal } from '../utils/functions'
import { mTel, mCEP, mCNPJ } from '../utils/masks'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function Produtos() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboTipoProduto, setComboTipoProduto] = useState([{ display: '', value: null }])
  const [comboSubgrupoProduto, setComboSubgrupoProduto] = useState([{ display: '', value: null }])
  const [comboLinhaProduto, setComboLinhaProduto] = useState([{ display: '', value: null }])
  const [comboClassificacaoContabil, setComboClassificacaoContabil] = useState([{ display: '', value: null }])
  const [comboClassificacaoFiscal, setComboClassificacaoFiscal] = useState([{ display: '', value: null }])
  const [comboUnidadesMedida, setComboUnidadesMedida] = useState([{ display: '', value: null }])
  const [comboAtivo, setComboAtivo] = useState([{ display: 'Sim', value: 'S' }, { display: 'Não', value: 'N' }])


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      let prefetchData = []
      prefetchData.push(api.getComboDataAsync('tipo_produto', setComboTipoProduto))
      prefetchData.push(api.getComboDataAsync('subgrupo_produtos', setComboSubgrupoProduto))
      prefetchData.push(api.getComboDataAsync('linha_produtos', setComboLinhaProduto))
      prefetchData.push(api.getComboDataAsync('classificacao_contabil', setComboClassificacaoContabil))
      prefetchData.push(api.getComboDataAsync('classificacao_fiscal', setComboClassificacaoFiscal))
      prefetchData.push(api.getComboDataAsync('unidades_medida', setComboUnidadesMedida))

      await Promise.all(prefetchData)


      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Produtos', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Produtos', 'Inclusao'))
      }

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/produtos?pk_pro=' + query.codigo).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])
            r.data[0].peso = r.data[0].peso ? String(String(r.data[0].peso.toFixed(3)).replace('.', ',')) : null
            setData(r.data[0])
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'fk_tip',
      'referencia',
      'descricao',
      'descricao_resumida',
      'fk_sgr',
      'fk_lin',
      'fk_und',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          auxValues.cnpj = onlyNumbers(auxValues.cnpj)
          auxValues.cep = onlyNumbers(auxValues.cep)
          auxValues.fone1 = onlyNumbers(auxValues.fone1)
          auxValues.fone2 = onlyNumbers(auxValues.fone2)
          auxValues.celular = onlyNumbers(auxValues.celular)
          auxValues.peso = auxValues.peso ? Number(String(auxValues.peso).replace(',', '.')) : null
          if (updateType) {
            api.post('/produtos/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/produtos/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'cnpj':
        auxValues[e.target.name] = mCNPJ(e.target.value);
        break

      case 'fone1':
      case 'fone2':
      case 'celular':
        auxValues[e.target.name] = mTel(e.target.value);
        break

      case 'cep':
        auxValues[e.target.name] = mCEP(e.target.value);
        break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Produtos</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>
          <FrSelect
            value={data.fk_tip || ''}
            onChange={handleChange}
            name="fk_tip"
            id="fk_tip"
            label='Tipo de Produto'
            data={comboTipoProduto}
            required
            style={{ width: 200 }}
          />
          <FrSelect
            value={data.ativo || ''}
            onChange={handleChange}
            name="ativo"
            id="ativo"
            label='Ativo'
            data={comboAtivo}
            required
            style={{ width: 100 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.referencia || ''}
            onChange={handleChange}
            name="referencia"
            id="referencia"
            color="#333333"
            label="Referência"
            variant="outlined"
            size="small"
            required
            style={{ width: 300 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.descricao || ''}
            onChange={handleChange}
            name="descricao"
            id="descricao"
            color="#333333"
            label="Descrição"
            variant="outlined"
            size="small"
            required
            style={{ width: 250 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.descricao_resumida || ''}
            onChange={handleChange}
            name="descricao_resumida"
            id="descricao_resumida"
            color="#333333"
            label="Descrição Resumida"
            variant="outlined"
            size="small"
            required
            style={{ width: 170 }}
          />
          <br />
          <FrSelect
            value={data.fk_sgr || ''}
            onChange={handleChange}
            name="fk_sgr"
            id="fk_sgr"
            label='Subgrupo de Produto'
            data={comboSubgrupoProduto}
            required
            style={{ width: 200 }}
          />
          <FrSelect
            value={data.fk_lin || ''}
            onChange={handleChange}
            name="fk_lin"
            id="fk_lin"
            label='Linha de Produto'
            data={comboLinhaProduto}
            required
            style={{ width: 200 }}
          />
          <FrSelect
            value={data.fk_ctb || ''}
            onChange={handleChange}
            name="fk_ctb"
            id="fk_ctb"
            label='Classificação Contábil'
            data={comboClassificacaoContabil}
            style={{ width: 200 }}
          />
          <FrSelect
            value={data.fk_fis || ''}
            onChange={handleChange}
            name="fk_fis"
            id="fk_fis"
            label='Classificação Fiscal'
            data={comboClassificacaoFiscal}
            style={{ width: 200 }}
          />
          <FrSelect
            value={data.fk_und || ''}
            onChange={handleChange}
            name="fk_und"
            id="fk_und"
            label='Unidade de Medida'
            data={comboUnidadesMedida}
            required
            style={{ width: 200 }}
          />
          <br />
          <FrTextInput
            maxLength='50'
            value={data.codigo_barras || ''}
            onChange={handleChange}
            name="codigo_barras"
            id="codigo_barras"
            color="#333333"
            label="Código de Barras"
            variant="outlined"
            size="small"
            style={{ width: 170 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.preco_venda || ''}
            onChange={handleChange}
            name="preco_venda"
            id="preco_venda"
            color="#333333"
            label="Preço de Venda"
            variant="outlined"
            size="small"
            style={{ width: 170 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.preco_venda_liquido || ''}
            onChange={handleChange}
            name="preco_venda_liquido"
            id="preco_venda_liquido"
            color="#333333"
            label="Preço de Venda - Líquido"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.preco_venda_dolar || ''}
            onChange={handleChange}
            name="preco_venda_dolar"
            id="preco_venda_dolar"
            color="#333333"
            label="Preço de Venda - Dolar"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.peso || ''}
            onChange={handleChange}
            name="peso"
            id="peso"
            color="#333333"
            label="Peso"
            variant="outlined"
            size="small"
            endAdornment={'kg'}
            required
            onBlur={(e) => {
              onBlurDecimal(e, 3, handleChange);
            }}
            style={{ width: 150 }}
          />
          <FrTextInput
            maxLength='20'
            value={data.cod_operacional || ''}
            onChange={handleChange}
            name="cod_operacional"
            id="cod_operacional"
            color="#333333"
            label="Cód. Operacional"
            variant="outlined"
            size="small"
            required
            style={{ width: 300 }}
          />

        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Produtos;
