import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ic_content_copy } from 'react-icons-kit/md/ic_content_copy'
import { qrcode } from 'react-icons-kit/icomoon/qrcode'
import Dialog from '@material-ui/core/Dialog';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import download from 'downloadjs'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSelect from '../components/FrSelect'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, getUserCode } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Caixas(props) {
  const [data, setData] = useState([])
  const [selected, setSelected] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [fk_lot, setFk_lot] = useState()
  const [comboTipo, setComboTipo] = useState([{ value: '', display: '' }])
  const [comboLote, setComboLote] = useState([{ value: '', display: '' }])
  const [comboProduto, setComboProduto] = useState([{ value: '', display: '' }])
  const [comboCliente, setComboCliente] = useState([{ value: '', display: '' }])
  const [comboAtivo, setComboAtivo] = useState([{ value: 'S', display: 'Ativo' }])
  const [mostraDialog, setMostraDialog] = useState()
  const [lock_cli, setLockCli] = useState(JSON.parse(localStorage['lock_cli']));
  const auth = JSON.parse(localStorage['authtarento'] || JSON.stringify({ authenticated: false, user: 0 }))
  

  const columns = [
    { id: 'id_caixa', numeric: false, label: 'Id.Acionamento', align: 'left', minWidth: 100 },
    { id: 'sufixo', numeric: false, label: 'Sufixo', align: 'left', minWidth: 100 },
    { id: 'nomecli', numeric: false, label: 'Cliente', align: 'left', minWidth: 250 },
    { id: 'nomepro', numeric: false, label: 'Produto', align: 'left', minWidth: 250 },
    { id: 'situacao', numeric: false, label: 'Situação', align: 'left', minWidth: 250 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      if (!props.frame) {
        setenableAdd(await api.getAcessos('Caixas', 'Inclusao'))
        setenableEdit(await api.getAcessos('Caixas', 'Alteracao'))
        setenableDel(await api.getAcessos('Caixas', 'Exclusao'))
        setComboTipo(await api.getComboData('tipo_caixa'))
        setComboProduto(await api.getComboData('produtos'))
        setComboCliente(await api.getComboData('clientes'))
        setLockCli(JSON.parse(localStorage['lock_cli']))
        setComboLote(await api.getComboData('lote'))
      
        if (auth.fk_cli > 0){
          let auxFilter= {...filter}
          auxFilter.fk_cli= auth.fk_cli
          setFilter(auxFilter)
        } 
        setComboAtivo([{ 
          value: 'S', display: 'Ativo' },
        { value: 'N', display: 'Inativo' }])

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        setFilter(query)
        if (lock_cli.lock_cli === 'S'){
          localStorage['fk_cli'] =  query.fk_cli;
        }
        populateForm(document.getElementById('searchForm'), query)
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
      swal.close()
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      if (auth.fk_cli > 0){
        queryObject.fk_cli = auth.fk_cli
      }
      setFiltered(true)
      if (!props.frame) {
        window.history.replaceState({ filtered: true }, 'filter', "/tarento/caixas?" + objectToQueryString(queryObject));

      }

      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/caixa', { params: queryObject }).then(r => {
        setData(r.data)

        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    window.history.replaceState({ filtered: false }, 'filter', '/tarento/caixas');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  const handleGerarEtiquetas = (e) => {
    e.preventDefault();
    if (selected.length > 0) {
      api.post('/caixa/geraEtiquetas', { ids: selected }, { responseType: "blob" }).then(function (response) {
        var blob = new Blob([response.data], {
          type: 'application/pdf'
        });
        download(blob, 'etiquetas.pdf')

      });
    } else swal.fire('Nenhum registro selecionado!', 'Selecione ao menos um registro para gerar as etiquetas.', 'warning')
  }

  const handleReplicarCaixa = (e) => {
    e.preventDefault();
    if (selected.length === 1) {
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      api.get('/caixa/checaReplicacao', { params: { id: selected[0] } }).then(async (r) => {
        if (r.status === 200) {
          const { value: qtd } = await swal.fire({
            title: 'Quantas replicações serão geradas?',
            // text: 'Lembrando: a quantidade deve ser ' + (r.data.qtd === 1 ? 'ímpar' : 'par') + ', pois está(ão) cadastrada(s) ' + r.data.qtd + ' caixa(s) com essa id.',
            input: 'number',
            inputLabel: 'Quantidade',
            inputValue: (r.data.qtd === 1 ? 1 : 2),
            showConfirmButton: true,
            allowOutsideClick: true,
            allowEscapeKey: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirma',
            cancelButtonText: 'Cancela',
            inputValidator: (value) => {              
              if (value <= 0) return 'Informe um número maior que zero.'              
            }
          })

          if (qtd) {
            swal.fire({
              html: <FrLoading text="Replicando caixa..." />,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false
            })

            api.post('/caixa/replica', { id: selected[0], qtd }).then((r) => {
              swal.close()
              swal.fire({
                title: 'Replicação completa',
                text: "Os registros foram adicionados com sucesso!",
                icon: 'success',
              }).then((result) => {
                if (result.value) {
                  let event = new Event('build');
                  getData(event)
                }
              })
            });
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "O registro não foi alterado!",
            icon: 'warning',
          })
        }
      });
    } else if (selected.length > 0) swal.fire('Mais de um registro selecionado!', 'Selecione somente um registro por vez para replicar.', 'warning')
    else swal.fire('Nenhum registro selecionado!', 'Selecione um registro para replicar.', 'warning')
  }

  const geraLeitura = (e) => {
    e.preventDefault()

    setMostraDialog(true)
  }

  const escondeModal = (e) => {
    setMostraDialog(false)
  }

  const confirmaLeitura = (e) => {
    
    setMostraDialog(false)
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.post('caixa/leituraManual_ConfereLocal', {caixas: selected, lote: fk_lot}).then(r=>{
      console.log(r.data)
      if(r.data == 'mesmo local'){
        swal.fire({
          title: 'Atenção',
          icon: 'warning',
          text: 'Serão incluídos '+selected.length+' acionamentos no lote '+ fk_lot + '. Confirma?',
          showConfirmButton: true,
          allowOutsideClick: true,
          allowEscapeKey: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirma',
          cancelButtonText: 'Cancela',
        }).then(r => {
          console.log(r)
          if (r.isConfirmed) {
            swal.fire({
              html: <FrLoading text="Carregando..." />,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            api.post('caixa/leituraManual', {caixas: selected, lote: fk_lot, fk_usu: getUserCode()}).then(r => {
              console.log(r.data)
              if(r.data == 'local diferente'){
                swal.fire('Conflito de local!', 'Acionamentos devem ser do mesmo local do lote.', 'warning')
              }else if (r.data == 'mesmo sufixo/id'){
                swal.fire('Conflito de id/sufixo', 'Já existe acionamento com mesmo id/sufixo no mesmo lote.', 'warning')
              } else if (r.data == 'certo'){
                swal.fire('Sucesso!', 'Acionamentos adicionados ao lote.', 'success')
              }
            })
          } else {
            setMostraDialog(true)
          }
        })

      } else  {
        swal.fire('Acionamentos em locais diferentes', 'Todos acionamentos selecionados devem ser do mesmo local de cliente.', 'warning')
      } 
    })
    
    // swal.fire({
    //   html: <FrLoading text="Carregando..." />,
    //   showConfirmButton: false,
    //   allowOutsideClick: false,
    //   allowEscapeKey: false
    // })

  }

  function handleChangeLote(e){
    e.preventDefault()

    setFk_lot(e.target.value)
  }

  return (
    
    <div className="Manut">
      <div>
        <Dialog aria-labelledby="simple-dialog-title" open={mostraDialog} fullWidth={true} maxWidth={'sm'}>
          <div style={{display: 'flex', alignItems: 'center', flexDirection:'column', backgroundColor: '#F7EFEF'}}>
            <h4 style={{marginTop: '8px', marginBottom: '32px'}}>Selecione o lote</h4>
            <FrSelect
                  value={fk_lot || ''}
                  style={{ width: 330 }}
                  name="fk_lot"
                  id="fk_lot"
                  label='Lote'
                  data={comboLote}
                  onChange={handleChangeLote}

                />

          </div>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv">
              <button onClick={confirmaLeitura}> Confirma </button>
              <button onClick={escondeModal}> Sair </button>
            </div>
          </div>
        </Dialog>
      </div>

      {props.frame ? <div /> : <NavBar />}
      <div className={"ManutContent" + (props.frame ? '-frame' : '')}>
        <div className="defaultHeader">
          <h3>Acionamentos</h3>
          {props.frame ? <div /> : <UserOptions />}
        </div>
        <div className="filtersDiv">

          <form id="searchForm" onSubmit={getData}>
            <div>

              {lock_cli.lock_cli === 'S' ? 
              <FrSelect
                value={filter.fk_cli || ''}
                style={{ width: 330 }}
                name="fk_cli"
                id="fk_cli"
                label='Cliente'
                data={comboCliente}
                disabled={auth.fk_cli>0}
                // onChange={handleChange}

              />: 
              <FrSelect
                value={filter.fk_cli || ''}
                style={{ width: 330 }}
                name="fk_cli"
                id="fk_cli"
                label='Cliente'
                disabled={auth.fk_cli>0}
                data={comboCliente}
                onChange={handleChange}
                // readOnly={lock_cli.lock_cli === true}
              />
              }

              <FrSelect
                value={filter.fk_pro || ''}
                style={{ width: 260 }}
                name="fk_pro"
                id="fk_pro"
                label='Produto'
                data={comboProduto}
                onChange={handleChange}
              />

              <FrTextInput
                value={filter.id_caixa || ''}
                style={{ width: 330 }}
                name="id_caixa"
                id="id_caixa"
                color="#333333"
                label="Id Caixa"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />

              <FrSelect
                value={filter.fk_tpc || ''}
                style={{ width: 170 }}
                name="fk_tpc"
                id="fk_tpc"
                label='Tipo de Caixa'
                data={comboTipo}
                onChange={handleChange}
              />                 

              <FrTextInput
                value={filter.cod_cliente || ''}
                style={{ width: 330 }}
                name="cod_cliente"
                id="cod_cliente"
                color="#333333"
                label="Código Cliente"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <FrSelect
                value={filter.ativo || ''}
                style={{ width: 330 }}
                name="ativo"
                id="ativo"
                label='Ativo'
                data={comboAtivo}
                onChange={handleChange}
                // readOnly={lock_cli.lock_cli === true}
              />

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {(enableAdd !== 'S') ? '' :
                <Link to="/tarento/caixas/registro" >
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}
              {(enableAdd !== 'S') ? '' :
                <button onClick={handleGerarEtiquetas}>
                  <Icon icon={qrcode} size={15} />
                  Gerar Etiquetas
                </button>}
              {(enableAdd !== 'S') ? '' :
                <button onClick={handleReplicarCaixa}>
                  <Icon icon={ic_content_copy} size={18} />
                  Replicar Acionamento
                </button>}
                <button onClick={(e) => geraLeitura(e)}>
                    Gerar Leitura
                  </button>
            </div>
          </form>
        </div>
        <br />
        <div className={"tableDiv" + (props.frame && data.length > 0 ? '-frame' : '')}>
          <FrTable regName="caixas" startOrder={'id_caixas'} setSelected={setSelected} frame={props.frame} columns={columns} searched={filtered} codeName="pk_cai" page={'Caixas'} data={data} enableEdit={enableEdit} enableDel={enableDel} />
        </div>
        {props.frame ? (<div>

        </div>) : <FooterDelphus />}

      </div>
    </div>
  );
}

export default Caixas;
