import React, { useState, useEffect, Component } from 'react';
import './index.css';
import FrTextInput from '../FrTextInput'
import { Icon } from 'react-icons-kit'
import { ic_search } from 'react-icons-kit/md/ic_search'
import {ic_done} from 'react-icons-kit/md/ic_done'
import {ic_close} from 'react-icons-kit/md/ic_close'
import Dialog from '@material-ui/core/Dialog';


function FrameF2(props) {
    const [showModal, setShowModal] = useState(props.open);

    const [name, setName] = useState(props.name)
    const [codeName, setCodeName] = useState(props.codeName)

    const [text, setText] = useState(null)
    // const [codeValue, setCodeValue] = useState(props.codeValue)
    // const [handleChangeCode, setHandleChangeCode] = useState(props.handleChangeCode)

    function buildComponent() {
        return React.createElement(props.tela, {frame: true})
    }


    function mostraModal(e) {
        e.preventDefault()
        setShowModal(true)
    }


    function abreF2(e) {
        e.preventDefault()
        if (e.keyCode === 113) {
            setShowModal(true)
        }
    }


    function abreF2Codigo(e) {
        if (e.keyCode === 113) {
            e.preventDefault()
            setShowModal(true)
        }
    }

    function procuraCodigo(e) {
        e.preventDefault()
        setText('Teste')
    }

    const handleChange = (e) => {
        const auxValues = text;
        console.log(auxValues)
        auxValues = e.target.value;
        setText(auxValues);
    };


    return (
        <div>
            <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>
                {buildComponent()}
                <div className="FrModalFooter">
                    <div className="FrModalButtonsDiv">
                        <button onClick={(e) => {e.preventDefault(); setShowModal(false)}} className="FrModalButton">
                            <Icon icon={ic_done} size={18} />
                            Selecionar
                        </button>
                        <button onClick={(e) => {e.preventDefault(); setShowModal(false)}} className="FrModalButton">
                            <Icon icon={ic_close} size={18} />
                            Cancelar
                        </button>
                    </div>
                </div>
            </Dialog>

            <div>
                <FrTextInput
                    maxLength='50'
                    value={props.value}
                    onChange={props.handleChangeCode}
                    name={codeName}
                    id={codeName}
                    color="#333333"
                    label="Cód."
                    variant="outlined"
                    size="small"
                    required
                    style={{ width: 100, marginRight: '2px',  }}
                    onKeyDown={abreF2Codigo}
                    onBlur={procuraCodigo}
                    endAdornment={(
                        <button className='buttonF2' onClick={mostraModal} tabIndex="-1">
                            <Icon icon={ic_search} size={20} />
                        </button>
                    )}
                />
                <FrTextInput
                    maxLength='50'
                    value={text}
                    onChange={handleChange}
                    name={name}
                    id={name}
                    label={name}
                    color="#333333"
                    variant="outlined"
                    size="small"
                    disabled
                    style={{ width: 330 }}
                    onKeyDown={abreF2}
                />
            </div>



        </div>
    );
}

export default FrameF2;