import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import QrReader from 'react-qr-scanner'
import withReactContent from 'sweetalert2-react-content'
import {history} from 'react-icons-kit/fa/history'
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import {back} from 'react-icons-kit/entypo/back'
import {ic_swap_horiz} from 'react-icons-kit/md/ic_swap_horiz'
import {ic_photo_camera} from 'react-icons-kit/md/ic_photo_camera'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrSelect from '../components/FrSelect'
import FrLoading from '../components/FrLoading'
import ModalLeitura from '../components/ModalLeitura'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/ResponsiveReg.css';
import FooterDelphus from '../components/FooterDelphus';
import { ListItem } from '@material-ui/core';

const swal = withReactContent(Swal)

function Leitura() {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authtarento'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [codigo, setCodigo] = useState(null)
  const [selectedCamera, setSelectedCamera] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [facingMode, setFacingMode] = useState('environment')
  const [constraints, setConstraints] = useState({ audio: false, video: { facingMode: 'environment'} })
  const [reading, setReading] = useState(false)
  const [leitura, setLeitura] = useState({})


  useEffect(() => {
    const fetchData = async () => {
      // swal.fire({
      //   html: <FrLoading text="Carregando..." />,
      //   showConfirmButton: false,
      //   allowOutsideClick: false,
      //   allowEscapeKey: false
      // })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      if (query.fk_cai) {
        handleRead({ text: window.location.href })
      }
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'descricao'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e, fk_cai) {
    e.preventDefault();
    setReading(false)
    setShowModal(false)
    swal.fire({
      html: <FrLoading text="Enviando leitura..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    let fk_usu = auth.user
    api.post('/caixa/confirmaLeitura', { fk_usu, fk_cai }).then((r) => {
      if (r.data && (r.status === 200)) {
        setLeitura(r.data)
        setTimeout(() => {
          swal.close()
          setTimeout(() => {
            swal.fire({
              title: 'Leitura efetuada!',
              text: "A leitura foi registrada com sucesso!",
              icon: 'success',
              timer: 1500
            })
          }, 500)
        }, 500)
      } else {
        swal.close()
        swal.fire({
          title: 'Erro!',
          text: "O leitura não foi registrada!",
          icon: 'warning',
        })
      }
    }).catch((e) => {
      swal.close()
      swal.fire({
        title: 'Erro!',
        text: "O leitura não foi registrada!",
        icon: 'warning',
      })
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'CNPJ':
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  const previewStyle = {
    height: '100%',
    width: '100%'
  }

  useEffect(() =>{
    setConstraints({
      audio: false,
      video: {facingMode: facingMode}
    })
    console.log(constraints)
  }, [facingMode])

  const handleChangeCamera = async (e) => {
    e.preventDefault();
    const userDevices = await navigator.mediaDevices.enumerateDevices()
    // userDevices.forEach(item=>window.alert(item.kind))

    
    const cameras = userDevices.filter(item => item.kind === "videoinput")
    // userDevices.forEach(item=>console.log(item.kind))

    // window.alert(userDevices.forEach(item=>{item.kind})
    console.log(cameras.length)
    let newIndex = selectedCamera+1 >= cameras.length ? 0 : selectedCamera + 1
    

    setSelectedCamera(newIndex)
    if (facingMode == 'user'){
      setFacingMode('environment')
    }
    if (facingMode == 'environment'){
      setFacingMode('user')
    }
    
  }


  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      setShowModal(false)
      setReading(false)
    }
  }

  const handleRead = (data) => {
    if (data && !reading) {
      setReading(true)
      swal.fire({
        html: <FrLoading text="Buscando registro..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      let fieldName = '?fk_cai='
      let fk_cai = data?.text.substr(data?.text.indexOf(fieldName) + fieldName.length, (data?.text.length - data?.text.indexOf(fieldName)))
      console.log(fk_cai)
      api.post('/caixa/leitura/'+fk_cai).then((r) => {
        if (r.data && (r.status === 200)) {
          setLeitura(r.data)
          setTimeout(() => {
            swal.close()
            setShowModal(true)
          }, 500)
        } else {
          swal.close()
          swal.fire({
            title: 'Erro!',
            text: "O registro não foi encontrado!",
            icon: 'warning',
          })
          setReading(false)
        }
      })
    }
  }



  return (
    <div className="responsiveReg">
      <ModalLeitura
        open={showModal}
        onClose={handleClose}
        onConfirm={handleSubmit}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        item={leitura}
      />
      <div className="responsiveHeader">
        <h3>Leitura</h3>
        <UserOptions />
      </div>
      <div className="responsiveRegContent">
        <div className="scanPreview">
          <button className="cameraButton" onClick={handleChangeCamera}><Icon icon={ic_swap_horiz} size={20} /> <Icon icon={ic_photo_camera} size={20} /></button>
          {navigator.mediaDevices.getUserMedia(constraints) ? (
            <QrReader
            delay={500}
            style={previewStyle}
            onError={(err) => console.log(err)}
            onScan={handleRead}
            constraints={constraints}
            />
           
          ) : (<>
          </>)}
        </div>
        <p>Posicione o QRCode no centro da câmera.</p>
        <Link to="/tarento/consulta-leitura">
          <button className="responsiveButton" >
            <Icon icon={history} size={18} />
            Consultar Leituras
          </button>
        </Link>
        <Link to="/tarento/home">
          <button className="responsiveButton" >
            <Icon icon={back} size={18} />
            Voltar
          </button>
        </Link>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Leitura;
