import React, { useState } from 'react';
import { Icon } from 'react-icons-kit'
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right'
import { ic_chevron_left } from 'react-icons-kit/md/ic_chevron_left'
import { ic_assignment } from 'react-icons-kit/md/ic_assignment'
import { ic_description } from 'react-icons-kit/md/ic_description'
import { ic_desktop_mac } from 'react-icons-kit/md/ic_desktop_mac'
import { ic_library_books } from 'react-icons-kit/md/ic_library_books'
import { ic_work } from 'react-icons-kit/md/ic_work'
import { ic_build } from 'react-icons-kit/md/ic_build'
import { routes } from '../../routes';

import Nav from 'react-bootstrap/Nav'
import Collapse from 'react-bootstrap/Collapse'
import Tooltip from '@material-ui/core/Tooltip';
import './index.css';
import icon from '../../imgs/logo.png';
import logo from '../../imgs/logo.png';
import { menu } from 'react-icons-kit/feather/menu';
import { NavBarLink } from '../NavBarLink';

function NavBar(props) {
    const [hide, setHide] = useState(true);
    const [animationStart, setAnimationStart] = useState(false)
    const [openCadastros, setOpenCadastros] = useState(false)
    const [openSistema, setOpenSistema] = useState(false)
    const [openAdmissao, setOpenAdmissao] = useState(false)
    const [openAuxiliares, setOpenAuxiliares] = useState(false)
    const [openLocalizacao, setOpenLocalizacao] = useState(false)
    const [openGerais, setOpenGerais] = useState(false)
    const [openInternacoes, setOpenInternacoes] = useState(false)
    const [openFinanceiros, setOpenFinanceiros] = useState(false)
    const [openFinanceiro2, setOpenFinanceiro2] = useState(false)
    const cadastros = routes.filter(item =>{
        return item.type == 'cadastros'
    }).sort((a, b) => {
        if(a.name > b.name){
            return 1;
        } 
        if (a.name < b.name){
            return -1;
        }
        return 0;
    })

    const gerais = routes.filter(item =>{
        return item.type == 'gerais'
    }).sort((a, b) => {
        if(a.name > b.name){
            return 1;
        } 
        if (a.name < b.name){
            return -1;
        }
        return 0;
    })

    const auxiliares = routes.filter(item =>{
        return item.type == 'auxiliares'
    }).sort((a, b) => {
        if(a.name > b.name){
            return 1;
        } 
        if (a.name < b.name){
            return -1;
        }
        return 0;
    })

    const operacional = routes.filter(item =>{
        return item.type == 'operacional'
    }).sort((a, b) => {
        if(a.name > b.name){
            return 1;
        } 
        if (a.name < b.name){
            return -1;
        }
        return 0;
    })

    const sistema = routes.filter(item =>{
        return item.type == 'sistema'
    }).sort((a, b) => {
        if(a.name > b.name){
            return 1;
        } 
        if (a.name < b.name){
            return -1;
        }
        return 0;
    })

    function toggleNavBarHide(e, item) {
        e.preventDefault();

        if (!hide) {
            setOpenCadastros(false)
            setOpenAdmissao(false)
            setOpenSistema(false)
            setOpenAuxiliares(false)
        }
        setAnimationStart(true)
        setHide(!hide)
    }

    function abreCadastro(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenCadastros(!openCadastros)

    }

    function abreAdmissao(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenAdmissao(!openAdmissao)

    }
    function abreSistema(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenSistema(!openSistema)

    }
    function clickAcionamento (){
        localStorage['lock_cli'] =  JSON.stringify({ lock_cli: 'N' });        
    }

    return (
        <div className={"Navbar" + (animationStart ? '-a': '')+(hide ? '-hide no-print' : ' no-print')}>
            <button onClick={toggleNavBarHide} className="toggle_navbar">
                {hide ? <Icon icon={ic_chevron_right} size={40} /> : <Icon icon={ic_chevron_left} size={40} />}
            </button>
            <Nav justify defaultActiveKey="/tarento/home" className="flex-column">
                <Tooltip title="Tela Principal">
                    <Nav.Link href="/tarento/home">
                        {hide ? <img alt='Expandir' src={icon} className={"fr_icon_navbar"+ (animationStart ? '-a': '')} /> : <img alt='Encolher' src={logo} className={"fr_logo_navbar"+ (animationStart ? '-a': '')} />}
                    </Nav.Link>
                </Tooltip>

                <button onClick={abreCadastro} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openCadastros ? " Open" : "")}>

                    <Icon icon={ic_assignment} size={20} />
                    {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Cadastros'}</strong>}
                </button>
                <Collapse in={openCadastros}>

                    <div className="toggleMenu">
                        {cadastros.map(item =>{
                            return <NavBarLink caption={item.caption || item.name} path={item.path} hide={hide}/>
                        })}

                        <button onClick={() => setOpenGerais(!openGerais)} className={(hide ? 'hide-' : '') + "toggleMenuButtonCollapse" + (openGerais ? " Open" : "")}>
                            <Icon icon={ic_library_books} size={20} />
                            {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Gerais'}</strong>}
                        </button>

                        <Collapse in={openGerais}>

                            <div className="toggleMenuCollapse">

                                {gerais.map(item =>{
                                    return <NavBarLink caption={item.caption || item.name} path={item.path} hide={hide}/>
                                })}

                            </div>

                        </Collapse>

                        <button onClick={() => setOpenAuxiliares(!openAuxiliares)} className={(hide ? 'hide-' : '') + "toggleMenuButtonCollapse" + (openAuxiliares ? " Open" : "")}>
                            <Icon icon={ic_library_books} size={20} />
                            {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Auxiliares'}</strong>}
                        </button>

                        <Collapse in={openAuxiliares}>

                            <div className="toggleMenuCollapse">

                                {auxiliares.map(item =>{
                                    return <NavBarLink caption={item.caption || item.name} path={item.path} hide={hide}/>
                                })}

                            </div>

                        </Collapse>

                    </div>
                </Collapse>

                <button onClick={abreAdmissao} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openAdmissao ? " Open" : "")}>
                    <Icon icon={ic_build} size={20} />
                    {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Operacional'}</strong>}
                </button>
                <Collapse in={openAdmissao}>
                    <div className="toggleMenu">
                        {operacional.map(item =>{
                            return <NavBarLink caption={item.caption || item.name} path={item.path} hide={hide}/>
                        })}

                        

                    </div>
                </Collapse>


                <button onClick={abreSistema} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openSistema ? " Open" : "")}>
                    <Icon icon={ic_desktop_mac} size={20} />
                    {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Sistema'}</strong>}
                </button>
                <Collapse in={openSistema}>
                    <div className="toggleMenu">

                        <NavBarLink caption='Grupo de Usuários' path='/grupo_usuarios' hide={hide}/>
                        <NavBarLink caption='Usuários' path='/usuarios' hide={hide}/>
                        
                    </div>
                </Collapse>



            </Nav>

        </div>
    );
}

export default NavBar;