import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import QrReader from 'react-qr-scanner'
import withReactContent from 'sweetalert2-react-content'
import {history} from 'react-icons-kit/fa/history'
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import {back} from 'react-icons-kit/entypo/back'
import {ic_swap_horiz} from 'react-icons-kit/md/ic_swap_horiz'
import {ic_photo_camera} from 'react-icons-kit/md/ic_photo_camera'
import Dialog from '@material-ui/core/Dialog';
import FrDatePicker from '../components/FrDatePicker';

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrSelect from '../components/FrSelect'
import FrLoading from '../components/FrLoading'
import ModalLeitura from '../components/ModalLeitura'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/ResponsiveReg.css';
import FooterDelphus from '../components/FooterDelphus';
import { ListItem } from '@material-ui/core';

const swal = withReactContent(Swal)

function Leitura() {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authtarento'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [codigo, setCodigo] = useState(null)
  const [selectedCamera, setSelectedCamera] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [facingMode, setFacingMode] = useState('environment')
  const [constraints, setConstraints] = useState({ audio: false, video: { facingMode: 'environment'} })
  const [reading, setReading] = useState(false)
  const [leitura, setLeitura] = useState({})
  const [localCliente, setLocalCliente] = useState(0);
  const [dataLote, setDataLote] = useState(0);
  const [pk_lot, setPk_lot] = useState(0);
  const [showModalLote, setShowModalLote] = useState(false)
  const [modal, setModal] = useState(false)
  const [totalAcionamentos, setTotalAcionamentos] =useState(0)
  const [comboLoc, setComboLoc] = useState([{ value: '', display: '' }])
  const timeElapsed = Date.now();
  const [lote, setLote] = useState(JSON.parse(localStorage['lote'] || JSON.stringify({ authenticated: false, user: 0 })));
  const date = new Date(timeElapsed)

 

  useEffect(() => {
    const fetchData = async () => {
      // swal.fire({
      //   html: <FrLoading text="Carregando..." />,
      //   showConfirmButton: false,
      //   allowOutsideClick: false,
      //   allowEscapeKey: false
      // })
      let prefetchData = []
      setComboLoc(await api.getComboDataWhere('local_cliente', (auth.fk_cli > 0 ? '?fk_cli=' + auth.fk_cli: '')))
      // prefetchData.push(api.getComboDataAsync('local_cliente', setComboLoc, (auth.fk_cli > 0 ? auth.fk_cli: '')))
      await Promise.all(prefetchData)

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      api.get('/caixa/lote').then(r =>{
        console.log(r.data.length)

        setDataLote((date.getDate() < 10 ? '0'+date.getDate() : date.getDate())+"/"+(date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1): date.getMonth()+1)+"/"+date.getFullYear())

        if (r.data.length>0){
          if (lote.fk_lot>0){
            console.log('entrou if', lote.fk_lot)
            setShowModalLote(true)
            setPk_lot(lote.fk_lot)
            setLocalCliente(lote.fk_loc)
            console.log(lote.fk_lot)
            localStorage.removeItem('lote');
          }else{
            setPk_lot(Number(r.data[0].pk_lot)+1)
          }

          
          // setDataLote(date)

        } else {
          setPk_lot(1)
        }
      })
      if (query.fk_cai) {
        handleRead({ text: window.location.href })
      }
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'descricao'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function confirmaLeitura (e, fk_cai) {
    let fk_usu = auth.user
    let fk_lot = pk_lot
    api.post('/caixa/confirmaLeitura', { fk_usu, fk_cai, fk_lot }).then((r) => {
      if (r.data && (r.status === 200)) {
        setLeitura(r.data)
        setTimeout(() => {
          swal.close()
          setTimeout(() => {
            swal.fire({
              title: 'Leitura efetuada!',
              text: "A leitura foi registrada com sucesso!",
              icon: 'success',
              timer: 1500
            })
          }, 500)
        }, 500)
        setTotalAcionamentos(totalAcionamentos+1)
        setShowModalLote(true)
      } else {
        console.log(r)
        swal.close()
        swal.fire({
          title: 'Erro!',
          text: "O leitura não foi registrada!",
          icon: 'warning',
        }).then(r =>{
          setShowModalLote(true)
        })
      }
    }).catch((e) => {
      swal.close()
      console.log(e)
      swal.fire({
        title: 'Erro!',
        text: "O leitura não foi registrada!",
        icon: 'warning',
      }).then(r =>{
        setShowModalLote(true)
      })
    })
  }


  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'CNPJ':
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  const previewStyle = {
    height: '100%',
    width: '100%'
  }

  useEffect(() =>{
    setConstraints({
      audio: false,
      video: {facingMode: facingMode}
    })
    // console.log(constraints)
  }, [facingMode])
  

  const handleChangeCamera = async (e) => {
    e.preventDefault();
    const userDevices = await navigator.mediaDevices.enumerateDevices()
    // userDevices.forEach(item=>window.alert(item.kind))

    
    const cameras = userDevices.filter(item => item.kind === "videoinput")
    // userDevices.forEach(item=>console.log(item.kind))

    // window.alert(userDevices.forEach(item=>{item.kind})
    let newIndex = selectedCamera+1 >= cameras.length ? 0 : selectedCamera + 1
    

    // setSelectedCamera(newIndex)
    // setConstraints({
    //   audio: false,
    //   video: {
    //     deviceId: {
    //         exact: cameras[newIndex].deviceId
    //     }
    //   }
    // })

    setSelectedCamera(newIndex)
    if (facingMode == 'user'){
      setFacingMode('environment')
    }
    if (facingMode == 'environment'){
      setFacingMode('user')
    }
  }


  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      setShowModal(false)
      setReading(false)
    }
    setShowModalLote(true)

  }
  

  function handleSubmit(e, fk_cai) {
    e.preventDefault();
    setReading(false)
    setShowModal(false)
    swal.fire({
      html: <FrLoading text="Enviando leitura..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('caixa?pk_cai='+fk_cai).then(r =>{
      console.log(r)
      if (r.data.length>0){
        console.log(r.data[0].fk_loc, Number(localCliente))
        console.log(Number(r.data[0].fk_loc) !== Number(localCliente))
        if (Number(r.data[0].fk_loc) !== Number(localCliente)){
          swal.fire('Erro!', 'O acionamento lido não pertence ao local indicado no lote, verifique!', 'warning').then(r => {
            if (r.value){
              setShowModalLote(true)
            } else {
              setShowModalLote(true)
            }
          })
          
        } else {
          confirmaLeitura(e, fk_cai)
        }
      }
    })
  }

  function registraLeitura (data) {
    let fieldName = '?fk_cai='
      let fk_cai = data?.text.substr(data?.text.indexOf(fieldName) + fieldName.length, (data?.text.length - data?.text.indexOf(fieldName)))
      // console.log(fk_cai)
      api.post('/caixa/leitura/'+fk_cai).then((r) => {
        if (r.data && (r.status === 200)) {
          setLeitura(r.data)
          setTimeout(() => {
            swal.close()
            setShowModal(true)
          }, 500)
        } else {
          swal.close()
          swal.fire({
            title: 'Erro!',
            text: "O registro não foi encontrado!",
            icon: 'warning',
          })
          setTotalAcionamentos(totalAcionamentos+1)
          setReading(false)
        }
      })
  }

  const handleRead = (data) => {
    if (data && !reading) {
      setReading(true)
      setShowModalLote(false)
      var limiteLeitura = new Date(); 
      swal.fire({
        html: <FrLoading text="Buscando registro..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      let naoLe=false
      let fieldName = '?id_caixa='
      let id = (data?.text.substr(data?.text.indexOf(fieldName) + fieldName.length, (data?.text.length - data?.text.indexOf(fieldName))))
      fieldName = '?fk_cai='
      let fk_cai = (data?.text.substr(data?.text.indexOf(fieldName) + fieldName.length, (data?.text.length - data?.text.indexOf(fieldName))))
      let ativo = ''
      api.get('caixa/leituraPeriodo?fk_cai='+fk_cai).then(r => {
        console.log('oi')
        console.log(r.data)
        if(r.data.length >0){
          console.log('passou length')
          ativo = r.data[0].ativo
          if(r.data[0].dias>0){
            console.log('passou dias')
            limiteLeitura.setDate(limiteLeitura.getDate() - r.data[0].dias)
            console.log(limiteLeitura)
            console.log(ativo)
            console.log(Date.parse(r.data[0].data))
            console.log()
            if(Date.parse(limiteLeitura) < Date.parse(r.data[0].data)){
              naoLe=true
            }
          }
        }
        naoLe = false //
        api.get('/caixa?pk_cai='+fk_cai).then((res) =>{
          ativo = res.data[0].ativo
          api.get('/caixa/encontraLeituraHoje/?fk_cai='+fk_cai).then( r => {
            let pk_cai = fk_cai
            if(ativo == 'N'){
              swal.fire('Erro!', "Acionamento Inativo!", 'warning').then(r => {
                setReading(false)
                setShowModalLote(true)
  
              })
            } else if(naoLe){
              // swal.close()
              swal.fire('Erro!', "Este acionamento foi registrado em intervalo inferior ao padrão - Verifique!", 'warning').then(r => {
                setReading(false)
                setShowModalLote(true)
  
              })
            } else if (r.data.length > 0){
              if(r.data[0].id == id){
                swal.fire('Atenção', "Já existe acionamento para esse ID/sufixo hoje! Operação Não pode ser concluída!", 'warning').then(r => {
                  setReading(false)
                  setShowModalLote(true)
  
                })
              } else {
                api.get('/caixa/',{pk_cai}).then( r => {
                  swal.fire({
                    title: 'Atenção', 
                    text: "Já existe leitura realizada hoje com sufixo "+ r.data[0].sufixo,
                    showConfirmButton: true,
                    showCancelButton: true,
                    allowOutsideClick: true,
                    allowEscapeKey: true
                  }).then(result => {
                    if(result.value){
                      registraLeitura(data)
                    } else {
                      setReading(false)
                      setShowModalLote(true)
                    }
                  })
                })
              }
            } else {
              registraLeitura(data)
            }
          })
        })

      })
    }

      // console.log(data)
  }

  const confirmaModalLote = () => {

  }


  const handleAbreLote = async (e) => {
    e.preventDefault()

    setModal(false)
    if(localCliente == 0){
      return swal.fire('Local inválido!', 'Informe o local para abertura de lote.', 'warning').then(r=>{
        setModal(true)
      })
    }
    swal.fire({
      html: <FrLoading text="Carregando..."/>,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('caixa/lote_aberto?fk_loc=' +localCliente).then( r=> {
      if (r.data.length == 0){
        api.post('caixa/abre_lote?pk_lot='+pk_lot+'&fk_usu='+auth.user+'&data='+dataLote+'&fk_loc='+localCliente+'&fk_slo=1').then(r=>{
          console.log('teste')
          swal.close()
          
          setShowModalLote(true)
        })
      } else {
        console.log(r.data[0])
        swal.close()
        swal.fire('Conflito de Lote!', 'Já existe Lote aberto para esse local.', 'warning')
      }
    })
  }

  const handleRegistraLeitura =  (e) =>{
    
    e.preventDefault()
    setModal(true)
  }

  const handleChangeLote = (e) => {
    // console.log(await navigator.mediaDevices.enumerateDevices())
    e.preventDefault()
    if (e.target.name == 'pk_lot'){
      setPk_lot(e.target.value)
    }
    if (e.target.name == 'data'){
      console.log(typeof e.target.value)
      console.log(e.target.value)
      setDataLote(e.target.value)
    }
    if (e.target.name == 'fk_loc'){
      setLocalCliente(e.target.value)
    }
  }

  const handleCloseModalLote = (e) =>{
    e.preventDefault()

    setShowModalLote(false)
    swal.fire({
      title: 'ATENÇÃO',
      text: totalAcionamentos + " acionamentos Realizados! Confirma Encerramento?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then( r => {

      if(r.value){
        swal.fire({
          html: <FrLoading text="Carregando..."/>,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        api.post('caixa/fecha_lote?pk_lot='+pk_lot).then(r=>{
          setShowModalLote(false)
          setPk_lot(pk_lot+1)
          swal.close()
          setTotalAcionamentos(0)
        })

      } else {
        setShowModalLote(true)
      }
    })
    
  }

  const handleCancelaAbertura = (e) =>{
    e.preventDefault()
    setModal(false)
  }

  return (
    <div className="Manut">
      <NavBar />
      <ModalLeitura
        open={showModal}
        onClose={handleClose}
        onConfirm={handleSubmit}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        item={leitura}
      />
      <Dialog aria-labelledby="simple-dialog-title" open={modal} fullWidth={false} maxWidth={'lg'}>
        <div className="FrModalFooter">
          
          <form id="searchForm" onSubmit={()=>{}} style={{display: 'grid', alignItems: 'center', justifyContent: 'center', paddingInline: '15px', paddingTop: '25px', maxWidth: '256px'}}>
            <div style={{display: 'grid', justifyContent: 'center'}}>

              <FrTextInput
                  maxLength='50'
                  value={pk_lot || ''}
                  onChange={handleChangeLote}
                  name="pk_lot"
                  id="pk_lot"
                  color="#333333"
                  label="Lote"
                  variant="outlined"
                  size="small"
                  disabled
                  required
                  style={{ width: '100%', marginInline: 'auto' }}
                />

                <FrTextInput
                  name="data"
                  id="data"
                  color="#528b46"
                  label="Data de Leitura"
                  variant="outlined"
                  size="small"
                  style={{ width: '100%', marginInline: 'auto' }}
                  unclear={true}
                  disabled
                  value={dataLote}
                  onChange={handleChangeLote}
                />

                <FrSelect
                  style={{ width: '100%', marginInline: 'auto' }}
                  name="fk_loc"
                  id="fk_loc"
                  label='Local Cliente'
                  data={comboLoc}
                  value={localCliente}
                  onChange={handleChangeLote}

                />
            </div>
            <div className="FrModalButtonsDiv" style={{display: 'flex', justifyContent: 'center', marginInline: 'auto'}}>
              <button  onClick={handleAbreLote}>
                <Icon icon={history} size={18} />
                Abrir lote
              </button>
              <button  onClick={handleCancelaAbertura}>
                <Icon icon={history} size={18} />
                Cancelar 
              </button>

            </div>
   
            <FooterDelphus />
          
          </form>  
        </div>
      </Dialog>
      <Dialog aria-labelledby="simple-dialog-title" open={showModalLote} fullWidth={false} maxWidth={'lg'} >
        <div className='ManutContent'>

          
          <br />
          <div style={{ display: 'flex', flex: 1, marginTop: '10px', flexDirection: 'column', justifyContent: 'center', alignItems:'center' }}>
            <div className="scanPreview">
              {/* <button className="cameraButton" onClick={handleChangeCamera}><Icon icon={ic_swap_horiz} size={20} /> <Icon icon={ic_photo_camera} size={20} /></button> */}
              {navigator.mediaDevices.getUserMedia(constraints) && showModalLote ? (
                <QrReader
                delay={500}
                style={previewStyle}
                onError={(err) => console.log(err)}
                onScan={handleRead}
                constraints={constraints}
                />
              
              ) : (<>
              </>)}
            </div>
            <p>Posicione o QRCode no centro da câmera.</p>
            
            <div className='FrModalButtonsDiv'>
            <button onClick={handleCloseModalLote}>
              Finalizar Lote
            </button>

            </div>
          </div>
        </div>

      </Dialog>
      
      <div className='ManutContent'>

        <div className="defaultHeader">
          <h3>Leitura</h3>
          <UserOptions />
        </div>
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={()=>{}}>
            <div>

            </div>
            <div>
              <button  onClick={handleRegistraLeitura}>
                <Icon icon={history} size={18} />
                Registrar Leitura
              </button>
              <Link to="/tarento/consulta-leitura">
                <button  >
                  <Icon icon={history} size={18} />
                  Consultar Leituras
                </button>
              </Link>
              <Link to="/tarento/home">
                <button  >
                  <Icon icon={back} size={18} />
                  Voltar
                </button>
              </Link>

            </div>
            <FooterDelphus />
          
          </form>  
        </div>
      </div>
    </div>
  );
}

export default Leitura;
