import React, { useState } from 'react';
import { Icon } from 'react-icons-kit'
import { userCircle } from 'react-icons-kit/fa/userCircle'
import { ic_exit_to_app } from 'react-icons-kit/md/ic_exit_to_app'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import './index.css';

const swal = withReactContent(Swal)

function UserOptions(props) {
    const [auth, setAuth] = useState(JSON.parse(localStorage['authtarento'] || JSON.stringify({ authenticated: false, user: 0 })));

    function logout(e) {
        e.preventDefault();
        e.preventDefault();
        swal.fire({
        title: 'Deseja sair do sistema?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                localStorage['authtarento'] =  JSON.stringify({ authenticated: false, user: 0 })
                window.location.href = '/tarento/login'
            }
        })
 
    }

    return (
        <div className="userOptions">
            <Icon icon={userCircle} size={24} style={{ marginRight: '5px', marginLeft: '5px' }}/>
            <div className="userName">{auth.name}</div>
            <button onClick={logout}>
                <Icon icon={ic_exit_to_app} size={24} />
            </button>
        </div>
    );
}

export default UserOptions;