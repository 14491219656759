import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrSelect from '../components/FrSelect'
import FrLoading from '../components/FrLoading'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import api from '../services/api'
import { paramsToObject, onBlurDecimal, } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function Caixas() {
  const [data, setData] = useState({ sufixo: 'A' })
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboTipoCaixa, setComboTipoCaixa] = useState([{ display: '', value: null }])
  const [comboImprime, setComboImprime] = useState([{ display: 'Sim', value: 'S' },{ display: 'Não', value:'N' }])
  const [comboCliente, setComboCliente] = useState([{ display: '', value: null }])
  const [comboProduto, setComboProduto] = useState([{ display: '', value: null }])
  const [comboLocal, setComboLocal] = useState([{ display: '', value: null }])
  const [comboUnidadesMedida, setComboUnidadesMedida] = useState([{ display: '', value: null }])
  const [auth, setAuth] = useState(JSON.parse(localStorage['authtarento'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [peso, setPeso] = useState(0)
  const [lock_cli, setLockCli] = useState(JSON.parse(localStorage['lock_cli']));
  const [id, setId] = useState([{ value: ''}])
  const [id_caixa, setIdCaixa] = useState(0)
  const [codOper, setCodOper] = useState(null)
  const [fkPro, setFkPro] = useState(null)
  const [fk_cli, setFk_cli] = useState(localStorage['fk_cli']);
  const [codProCli, setCodProCli] = useState(null)
  const [qtdAcionamento, setQtdAcionamento] = useState(null)
  const [fkFator, setFkFator] = useState(null)
  const [refresh, setRefresh] = useState(true)
  const [quantidade, setQuantidade] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      if (refresh){
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)


      let prefetchData = []
      prefetchData.push(api.getComboDataAsync('tipo_caixa', setComboTipoCaixa))
      prefetchData.push(api.getComboDataAsync('produtos', setComboProduto))
      prefetchData.push(api.getComboDataAsync('clientes', setComboCliente))
      prefetchData.push(api.getComboDataAsync('unidades_medida', setComboUnidadesMedida))
      // prefetchData.push(api.getComboDataAsync('local_cliente', setComboLocal))
      setLockCli(JSON.parse(localStorage['lock_cli']))
      
         

      await Promise.all(prefetchData)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Caixas', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Caixas', 'Inclusao'))
        
      }
      
      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/caixa?pk_cai=' + query.codigo).then(r => {
          if (r.data[0]) {
            r.data[0].fator = r.data[0].fator ? String(String(r.data[0].fator.toFixed(3)).replace('.', ',')) : null
            setData(r.data[0])
            let auxValues = r.data[0]
            setFkFator(auxValues.fator)
            console.log(fkFator)
            setFk_cli(r.data[0].fk_cli)
            setCodOper(r.data[0].cod_operacional)
            setCodProCli(r.data[0].cod_cliente)
            setIdCaixa(r.data[0].id_caixa)
            setFkPro(r.data[0].fk_pro)
            setQtdAcionamento(auxValues.qtd_acionamento)
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else {
        setUpdateType(false)
        let auxValues = {...data}
        if(lock_cli.lock_cli === 'S'){
          setFk_cli(Number(localStorage['fk_cli']))  
          
          auxValues.fk_cli = fk_cli
          

        }   
        auxValues.ativo = 'S'
        setData(auxValues)
        console.log('data')
        console.log(data)
        api.get('caixa/id').then(r => {
          setIdCaixa(r.data[0].value)

        })
        swal.close()}
      setRefresh(false)
      }
    }

    fetchData()
  }, [qtdAcionamento, fkFator])


  useEffect (() =>{
    if (data.fk_cli > 0){
      api.getComboDataWhere('local_cliente', '?fk_cli=' + data.fk_cli).then(r => {
        console.log(r)
        if (r.length > 0){
          console.log('teste')
          setComboLocal(r)
        } else {
          setComboLocal([{ display: '', value: null }])
        }
      })  
    }
    // prefetchData.push(api.getComboDataAsync('local_cliente', setComboLocal))
  }, [data.fk_cli])

  const calcPeso = (e) => {
    e?.preventDefault()
    swal.fire({
      html: <FrLoading text="Calculando peso..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    let pro = fkPro
    let fator = fkFator
    console.log('calcpeso')
    
    api.get('/caixa/peso', {
      params: {
        qtd: data.qtd ? Number(String(data.qtd).replace(',', '.')) : null,
        fk_und: data.fk_und_cli ? Number(String(data.fk_und_cli).replace(',', '.')) : null,
        fk_pro: pro ? Number(String(pro).replace(',', '.')) : null,
        fk_tpc: data.fk_tpc ? Number(String(data.fk_tpc).replace(',', '.')) : null,
        fator: fator ? Number(String(fator).replace(',', '.')) : null,
        qtd_caixa: data.qtd_caixa ? Number(String(data.qtd_caixa).replace(',', '.')) : null,
      }
    }).then(r => {
      if (r.data) {
        setPeso(r.data.peso ? Number(r.data.peso).toFixed(4) : null)
        swal.close()
      } else {
        swal.fire('Oops...', 'Houve um problema no cálculo do peso!', 'warning')
          .then((result) => {
            if (result.value) {
              // window.history.back()
            }
          })
      }
    })

  }

  // useEffect(() => {
  //   calcPeso()
  // }, [data.fk_tpc, data.fk_und_cli, data.fk_pro, data.qtd_caixa])

  function consistData(data) {
    let required = [
      'sufixo',
      'fk_tpc',
      // 'qtd_acionamento',
      'fk_cli',
      'fk_pro',
      'fk_und_cli',
      'fator',
      'fk_loc',
      'qtd_caixa'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'fk_und_cli') fieldName = 'Unidade de Medida – Cliente'
      if (curr === 'fk_loc') fieldName = 'Local Cliente'
      if (curr === 'fk_tpc') fieldName = 'Tipo de Caixa'
      if (curr === 'fk_pro') fieldName = 'Produtos'
      if (curr === 'qtd_acionamento') fieldName = 'Qtd. Acionamentos'
      if (curr === 'fk_cli') fieldName = 'Cliente'
      if (curr === 'qtd_caixa') fieldName = 'Qtd. Caixa'
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }
  // useEffect(() => {
    const calcQtd = (e) => {
      // e.preventDefault();
      e.persist();
      api.get('/unidades_medida/unidades', {params:{
        pk_pro: fkPro ? (fkPro) : null,
      }
      }).then (r =>{
      let auxCentos = data.qtd
      let auxUnd = r.data[0].unidades
      let acionamento = auxCentos*auxUnd
      let auxValues = {... data}
      console.log(data)

      
      setQtdAcionamento(acionamento)
      console.log('aqui comeca tudo')
      console.log(auxValues)
      auxValues.qtd_acionamento = acionamento
      auxValues.qtd = auxCentos 
      console.log(auxValues)
      setData(auxValues)
      console.log(data)
      calcPeso(e)
      })
    }

  // }, [qtdAcionamento])
  // useEffect(() => {
  //   calcQtd()
  // }, [qtdAcionamento])


   const handleCod = (e) => {
    // e.preventDefault();
    e.persist();
    const urlParams = new URLSearchParams(window.location.search);
    let encontrado = true
    let query = paramsToObject(urlParams) 
    if (e.target.name == 'edi'){
      api.get('/cliente_produto/codigos', {params:{
        fk_cli: data.fk_cli ? Number(String(data.fk_cli).replace(',', '.')) : fk_cli ? Number(String(fk_cli).replace(',', '.')) : null,
        edi: codProCli ? (String(codProCli).replace(',', '.')) : null,
      }
      }).then (r => {
        console.log('oi')
        if ((r.data[0]) && (encontrado)){
          console.log(r.data)
          let auxValues = {...data}
          setFkPro(r.data[0].pk_pro)
          setCodOper(r.data[0].cod_operacional)
          auxValues.fk_pro = fkPro
          auxValues.cod_operacional = codOper
          handleChangePro(fkPro)
          setData(auxValues)
      } else {
        encontrado = false
        swal.fire('Oops...', 'Produto não encontrado!', 'warning')
          
        }
      })
    }
    
  

    if(e.target.name == 'cod_operacional'){
      api.get('/produtos/produto', {params:{
        cod_operacional: codOper ? Number(String(codOper).replace(',', '.')) : null,
        fk_cli: data.fk_cli ? Number(String(data.fk_cli).replace(',', '.')) : fk_cli ? Number(String(fk_cli).replace(',', '.')) : null,
      }
      }).then (r =>{
        console.log((r.data[0]) && (encontrado))
        if (r.data[0]){
          
          let auxValues = {...data}
          console.log(auxValues)

          setFkPro(r.data[0].pk_pro)
          setCodProCli(r.data[0].edi) 
          auxValues.fk_pro = fkPro
          auxValues.cod_cliente = codProCli
          handleChangePro()
          setData(auxValues)
        } else {
          encontrado = false
          swal.fire('Oops...', 'Produto não encontrado!', 'warning')
            
        }     

      })
    }
    
    if (e.target.name == 'fk_pro'){
      api.get('/produtos/codigos', {params:{
        pk_pro: fkPro ? Number(String(fkPro).replace(',', '.')) : null,
        fk_cli: data.fk_cli ? Number(String(data.fk_cli).replace(',', '.')) : fk_cli ? Number(String(fk_cli).replace(',', '.')) : null,
      }
      }).then (r =>{
        console.log('oi')
        if((r.data[0]) && (encontrado)){
          console.log('pássou')
          console.log(r.data)
          let auxValues = {...data}
          setCodOper(r.data[0].cod_operacional)
          setCodProCli(r.data[0].edi)
          auxValues.cod_cliente = codProCli
          auxValues.cod_operacional = codOper
          handleChangePro()
          setData(auxValues)
        }else {
          encontrado = false
          swal.fire('Oops...', 'Produto não encontrado!', 'warning')
           
        }
      })
    }     
  }

  function dataFormatadaSql (d) {
    let date = ''
    date =d.getFullYear()
    date = date+ '-' + (d.getMonth()+1 > 9 ? d.getMonth()+1 : '0'+String(d.getMonth()+1))
    date = date+ '-' + (d.getDate() > 9 ? d.getDate() : '0'+String(d.getDate()))


    return date
  }

  function handleSubmit(e) {
    e.preventDefault();
    let text = "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?'

    api.get('/caixa/id?='+id_caixa + '&data_cad='+ dataFormatadaSql(new Date())).then(r =>{
      if (r.data.length > 0 && updateType){
        text = "Este acionamento já foi registrado hoje com sufixo "+r.data.sufixo+", confirma a leitura?"
      }
      swal.fire({
        title: 'ATENÇÃO',
        text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          const auxValues = { ...data };
          auxValues.fk_usu_cadastro = auth.user
          auxValues.fk_pro = fkPro
          auxValues.cod_cliente = codProCli
          auxValues.id_caixa = id_caixa
          setQuantidade(data.qtd)
          console.log('quantidade='+quantidade)
          auxValues.qtd_acionamento = qtdAcionamento
          auxValues.fk_cli = lock_cli.lock_cli === 'S' ? fk_cli : data.fk_cli
          auxValues.fator = auxValues.fator ? Number(String(auxValues.fator).replace(',', '.')) : null
          let errors = consistData(auxValues)
          if (errors.length === 0) {
            if (updateType) {
  
              api.post('/caixa/edit', auxValues).then(r => {
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Alterado',
                    text: "O registro foi alterado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      window.history.back()
                    }
                  })
                } else {
                  swal.fire({
                    title: 'Erro!',
                    text: "O registro não foi alterado!",
                    icon: 'warning',
                  })
                }
              })
            } else {
              api.post('/caixa/add', auxValues).then(r => {
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      window.history.back()
                    }
                  })
                } else {
                  swal.fire({
                    title: 'Erro!',
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            }
          } else {
            swal.fire({
              title: 'Erro!',
              text: "Verifique o(s) campo(s) " + errors.join(','),
              icon: 'warning',
            })
          }
        }
      })


    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }
  const handleChangePro = () =>  {
    let auxValues = {... data}
    api.get('/unidades_medida/unidades', {params:{
      pk_pro: fkPro ? (fkPro) : null,
    }
    }).then (r =>{
        auxValues.fk_und_cli = r.data[0].fk_und
        auxValues.sigla = r.data[0].sigla
        auxValues.unidades = r.data[0].unidades
        setFkFator(r.data[0].fator)
        auxValues.fator = r.data[0].fator
        setData(auxValues)
    })
  }
  useEffect (() =>{
    handleChangePro()
  }, [quantidade])




  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'cod_operacional': 
        setCodOper(e.target.value)
        break

      case 'fk_pro': 
        setFkPro(e.target.value)
        break
        

      case 'ativo':
        auxValues[e.target.name] = (auxValues[e.target.name] === 'S' ? 'N' : 'S')
        break

      case 'fk_cli': 
        if (lock_cli.lock_cli === 'S'){
          auxValues[e.target.name] = fk_cli

        } else {
          auxValues[e.target.name] = e.target.value;
        }
        break

      case 'edi': 
        setCodProCli(e.target.value)
        break

      // case 'fk_cli': setFk_cli(e.target.value)
      case 'CNPJ':
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  const siglaUnidade = (e) => {

  }
  // useEffect(() => {
  //   siglaUnidade()
  // }, [data.sigla])


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Acionamento</h3>
          <UserOptions />
        </div>
        <br />
        
        <form id="regForm" onSubmit={handleSubmit}>
        {lock_cli.lock_cli === 'S' ? 
              <FrSelect
                value={data.fk_cli|| ''}
                style={{'&.MuiInputLabel-shrink' : {
                  padding: '5px',
                  top: '0',
                  color: '#333333'
                }, width: 330  }}
                name="fk_cli"
                id="fk_cli"
                label='Cliente'
                data={comboCliente}
                onChange={handleChange}
                disabled
                

              />: 
              <FrSelect
                value={data.fk_cli || ''}
                style={{ width: 330 }}
                name="fk_cli"
                id="fk_cli"
                label='Cliente'
                data={comboCliente}
                onChange={handleChange}
                // readOnly={lock_cli.lock_cli === true}
              />
              }
        <FormControlLabel
          control={
            <Checkbox
              value={data.ativo || ''}
              checked={data.ativo === 'S'}
              name="ativo"
              id="ativo"
              size='small'
              color="primary"
              onChange={handleChange}
            />
          }
          label="Ativo"
        />
        <br/>

        <FrTextInput
          maxLength='50'
          value={codProCli || ''}
          onChange={handleChange}
          name="edi"
          id="edi"
          color="#333333"
          label={"Cód. Produto Cliente"}
          variant="outlined"
          size="small"
          required
          onBlur={handleCod}
          style={{ width: 330 }}
        /> 
        
        <FrTextInput
          maxLength='15'
          value={codOper || ''}
          onChange={handleChange}
          name="cod_operacional"
          id="cod_operacional"
          color="#333333"
          label="Cód. Operacional"
          variant="outlined"
          size="small"
          required
          onBlur={handleCod}
          style={{ width: 270 }}
        />
        <FrSelect
          value={fkPro || ''}
          onChange={handleChange}
          name="fk_pro"
          id="fk_pro"
          label='Produto'
          onBlur={handleCod}
          data={comboProduto}
          style={{ width: 250 }}
        />
        <FrTextInput
          maxLength='15'
          value={id_caixa || ''}
          onChange={handleChange}
          name="id_caixa"
          id="id_caixa"
          color="#333333"
          label="Id Acionamento"
          variant="outlined"
          size="small"
          required
          disabled
          style={{ width: 170 }}
        />
        <FrTextInput
          maxLength='15'
          value={data.sufixo || ''}
          onChange={handleChange}
          name="sufixo"
          id="sufixo"
          color="#333333"
          label="Sufixo"
          variant="outlined"
          size="small"
          required
          disabled
          style={{ width: 150 }}
        />
        <FrSelect
          value={data.fk_tpc || ''}
          onChange={handleChange}
          name="fk_tpc"
          id="fk_tpc"
          label='Tipo de Caixa'
          data={comboTipoCaixa}
          style={{ width: 250 }}
        />
        
        <FrTextInput
          maxLength='50'
          value={data.qtd_caixa || ''}
          onChange={handleChange}
          name="qtd_caixa"
          id="qtd_caixa"
          color="#333333"
          label="Qtd. Caixas"
          variant="outlined"
          size="small"
          onBlur={calcPeso}
          required
          style={{ width: 100 }}
        />

        <FrTextInput
          maxLength='50'
          value={data.qtd || ''}
          onChange={handleChange}
          name="qtd"
          id="qtd"
          color="#333333"
          label="Qtd"
          variant="outlined"
          size="small"
          endAdornment={data.sigla}
          onBlur={calcQtd}
          required
          style={{ width: 100 }}
        />

          
     

        {/* <FrTextInput
          maxLength='50'
          value={data.qtd_acionamento || ''}
          onChange={handleChange}
          name="qtd_acionamento"
          id="qtd_acionamento"
          color="#333333"
          label="Qtd. Acionamento"
          variant="outlined"
          size="small"
          onBlur={calcPeso}
          required
          style={{ width: 150 }}
        /> */}
        
        <br />
                
        {/* <FrSelect
          value={data.fk_und_cli || ''}
          onChange={handleChange}
          name="fk_und_cli"
          id="fk_und_cli"
          label='Unidade de Medida - Cliente'
          data={comboUnidadesMedida}
          style={{ width: 250 }}
        /> */}
        {/* <FrTextInput
          maxLength='50'
          value={data.fator || ''}
          onChange={handleChange}
          name="fator"
          id="fator"
          color="#333333"
          label="Fator"
          variant="outlined"
          size="small"
          required
          onBlur={(e) => {
            onBlurDecimal(e, 3, handleChange);
            calcPeso(e)
          }}
          style={{ width: 150 }}
        /> */}
        <FrSelect
          value={data.fk_loc || ''}
          onChange={handleChange}
          name="fk_loc"
          id="fk_loc"
          label='Local - Cliente'
          data={comboLocal}
          style={{ width: 250 }}
        />
        <FrTextInput
          maxLength='50'
          value={peso || ''}
          color="#333333"
          label="Peso"
          variant="outlined"
          size="small"
          endAdornment={'kg'}
          disabled
          style={{ width: 150 }}
        />
        <FrSelect
          value={data.imprime_qrcode || ''}
          onChange={handleChange}
          name="imprime_qrcode"
          id="imprime_qrcode"
          label='Imprime QR Code'
          onBlur={handleCod}
          data={comboImprime}
          style={{ width: 140 }}
        />

        

      </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Caixas;
