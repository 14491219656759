import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import $ from 'jquery'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrSelect from '../components/FrSelect'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import consultaCep from '../services/consultaCep'
import { paramsToObject, onlyNumbers, validarCNPJ } from '../utils/functions'
import { mTel, mCEP, mCNPJ } from '../utils/masks'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function Clientes() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboCidades, setComboCidades] = useState([{ display: '', value: null }])
  const [comboIna, setComboIna] = useState([{ display: '', value: null }])
  const [comboNatureza, setComboNatureza] = useState([{ display: '', value: null }])
  const [comboTipoCliente, setComboTipoCliente] = useState([{ display: '', value: null }])
  const [comboSituacao, setComboSituacao] = useState([{ display: '', value: null }])
  const [erroCep, setErroCep] = useState(false)
  const [erroCnpj, setErroCnpj] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      let prefetchData = []
      prefetchData.push(api.getComboDataAsync('cidades', setComboCidades))
      prefetchData.push(api.getComboDataAsync('intervalo_acionamento', setComboIna))
      prefetchData.push(api.getComboDataAsync('tipo_cliente', setComboTipoCliente))
      prefetchData.push(api.getComboDataAsync('situacao', setComboSituacao))
      prefetchData.push(api.getComboDataAsync('natureza_operacao', setComboNatureza))

      await Promise.all(prefetchData)


      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Clientes', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Clientes', 'Inclusao'))
      }

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/clientes?pk_cli=' + query.codigo).then(r => {
          if (r.data[0]) {
            r.data[0].cnpj = mCNPJ(r.data[0].cnpj || '')
            r.data[0].fone1 = mTel(r.data[0].fone1 || '')
            r.data[0].fone2 = mTel(r.data[0].fone2 || '')
            r.data[0].celular = mTel(r.data[0].celular || '')
            r.data[0].cep = mCEP(r.data[0].cep || '')
            setData(r.data[0])
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'razao_social',
      'fantasia',
      'cnpj',
      'insc_estadual',
      'insc_municipal',
      'fk_cid',
      'cep',
      'logradouro',
      'numero',
      'bairro',
      'complemento',
      'fone1',
      'fone2',
      'celular',
      'email'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
     
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        if (errors.length === 0) {
          auxValues.cnpj = onlyNumbers(auxValues.cnpj)
          auxValues.cep = onlyNumbers(auxValues.cep)
          auxValues.fone1 = onlyNumbers(auxValues.fone1)
          auxValues.fone2 = onlyNumbers(auxValues.fone2)
          auxValues.celular = onlyNumbers(auxValues.celular)
          if (updateType) {
            api.post('/clientes/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/clientes/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'cnpj':
        auxValues[e.target.name] = mCNPJ(e.target.value);
        break

      case 'fone1':
      case 'fone2':
      case 'celular':
        auxValues[e.target.name] = mTel(e.target.value);
        break

      case 'cep':
        auxValues[e.target.name] = mCEP(e.target.value);
        break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  function handleCep(e) {
    e.preventDefault();
    swal.fire({
      html: <FrLoading text="Buscando CEP..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    if (e.target.value) {
      if (e.target.value) {
        consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
          swal.close()
          if (r.data.erro) {
            setErroCep(true)
            swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
          } else {
            setErroCep(false)
            const auxValues = { ...data };
            auxValues['logradouro'] = r.data.logradouro;
            auxValues['bairro'] = r.data.bairro;
            let cidade = comboCidades.find(item => String(item.display).toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") === String(r.data.localidade).toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
            if (cidade) auxValues['fk_cid'] = cidade.value
            else alert('Cidade não cadastrada no sistema!')
            setData(auxValues)
          }
        }).catch(err => {
          setErroCep(true)
          swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
        })
      }
    }
  }

  function handleCnpj(e) {
    e.preventDefault();
    let cnpj = e.target.value
    if (cnpj) {
      if (!validarCNPJ(cnpj)) {
        setErroCnpj(true)
        swal.fire('CNPJ inválido', 'Informe um CNPJ válido!', 'warning')
      } else {
        setErroCnpj(false)
      }
    }
  }

  

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Clientes</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>
          <FrTextInput
            maxLength='50'
            error={erroCnpj}
            value={data.cnpj || ''}
            onChange={handleChange}
            name="cnpj"
            id="cnpj"
            color="#333333"
            label="CNPJ"
            variant="outlined"
            size="small"
            onBlur={handleCnpj}
            required
            style={{ width: 170 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.razao_social || ''}
            onChange={handleChange}
            name="razao_social"
            id="razao_social"
            color="#333333"
            label="Razão Social"
            variant="outlined"
            size="small"
            required
            style={{ width: 400 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.fantasia || ''}
            onChange={handleChange}
            name="fantasia"
            id="fantasia"
            color="#333333"
            label="Fantasia"
            variant="outlined"
            size="small"
            required
            style={{ width: 300 }}
          />

          <br />
          <FrTextInput
            maxLength='50'
            value={data.insc_estadual || ''}
            onChange={handleChange}
            name="insc_estadual"
            id="insc_estadual"
            color="#333333"
            label="Inscrição Estadual"
            variant="outlined"
            size="small"
            required
            style={{ width: 170 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.insc_municipal || ''}
            onChange={handleChange}
            name="insc_municipal"
            id="insc_municipal"
            color="#333333"
            label="Inscrição Municipal"
            variant="outlined"
            size="small"
            required
            style={{ width: 170 }}
          />
          <FrSelect
            value={data.fk_tcl || ''}
            onChange={handleChange}
            name="fk_tcl"
            id="fk_tcl"
            label='Tipo de Cliente'
            data={comboTipoCliente}
            required
            style={{ width: 200 }}
          />
          <FrSelect
            value={data.fk_sit || ''}
            onChange={handleChange}
            name="fk_sit"
            id="fk_sit"
            label='Situação'
            data={comboSituacao}
            required
            style={{ width: 200 }}
          />
          <br />
          <FrTextInput
            maxLength='50'
            error={erroCep}
            value={data.cep || ''}
            onChange={handleChange}
            name="cep"
            id="cep"
            color="#333333"
            label="CEP"
            variant="outlined"
            size="small"
            onBlur={handleCep}
            required
            style={{ width: 100 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.logradouro || ''}
            onChange={handleChange}
            name="logradouro"
            id="logradouro"
            color="#333333"
            label="Logradouro"
            variant="outlined"
            size="small"
            required
            style={{ width: 400 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.numero || ''}
            onChange={handleChange}
            name="numero"
            id="numero"
            color="#333333"
            label="Número"
            variant="outlined"
            size="small"
            required
            style={{ width: 120 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.bairro || ''}
            onChange={handleChange}
            name="bairro"
            id="bairro"
            color="#333333"
            label="Bairro"
            variant="outlined"
            size="small"
            required
            style={{ width: 120 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.complemento || ''}
            onChange={handleChange}
            name="complemento"
            id="complemento"
            color="#333333"
            label="Complemento"
            variant="outlined"
            size="small"
            style={{ width: 300 }}
          />

          <FrSelect
            value={data.fk_cid || ''}
            onChange={handleChange}
            name="fk_cid"
            id="fk_cid"
            label='Cidade'
            data={comboCidades}
            style={{ width: 300 }}
            required
          />

          <FrSelect
            value={data.fk_nat || ''}
            onChange={handleChange}
            name="fk_nat"
            id="fk_nat"
            label='Natureza Operação'
            data={comboNatureza}
            style={{ width: 200 }}
            required
          />
          <br />
          <FrTextInput
            maxLength='50'
            value={data.fone1 || ''}
            onChange={handleChange}
            name="fone1"
            id="fone1"
            color="#333333"
            label="Fone"
            variant="outlined"
            size="small"
            required
            style={{ width: 150 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.fone2 || ''}
            onChange={handleChange}
            name="fone2"
            id="fone2"
            color="#333333"
            label="Fone"
            variant="outlined"
            size="small"
            style={{ width: 150 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.celular || ''}
            onChange={handleChange}
            name="celular"
            id="celular"
            color="#333333"
            label="Celular"
            variant="outlined"
            size="small"
            style={{ width: 150 }}
          />
          <FrTextInput
            maxLength='50'
            value={data.email || ''}
            onChange={handleChange}
            name="email"
            id="email"
            color="#333333"
            label="Email"
            variant="outlined"
            size="small"
            required
            style={{ width: 400 }}
          />
          <br />
          <FrSelect
            value={data.agrupa_pedido || ''}
            onChange={handleChange}
            name="agrupa_pedido"
            id="agrupa_pedido"
            label='Agrupa Pedido'
            data={[{ display: 'Sim', value: 'S' }, { display: 'Não', value: 'N' }]}
            style={{ width: 250 }}
          />
          <FrTextInput
            maxLength='20'
            value={data.cod_operacional || ''}
            onChange={handleChange}
            name="cod_operacional"
            id="cod_operacional"
            color="#333333"
            label="Cód. Operacional"
            variant="outlined"
            size="small"
            required
            style={{ width: 300 }}
          />
          <FrSelect
            value={data.fk_ina || ''}
            onChange={handleChange}
            name="fk_ina"
            id="fk_ina"
            label='Intervalo de Leitura Padrão'
            data={comboIna}
            style={{ width: 300 }}
            required
          />

        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Clientes;
