import Login from '../pages/Login';
import Home from '../pages/Home';
import Cidades from '../pages/Cidades';
import RegCidades from '../pages/RegCidades';
import Estados from '../pages/Estados';
import RegEstados from '../pages/RegEstados';
import Grupo_Usuarios from '../pages/Grupo_Usuarios';
import RegGrupo_Usuarios from '../pages/RegGrupo_Usuarios';
import Usuarios from '../pages/Usuarios';
import RegUsuarios from '../pages/RegUsuarios';
import ChangePw from '../pages/ChangePw';
import ForgotPw from '../pages/ForgotPw';
import ClassificacaoContabil from '../pages/ClassificacaoContabil';
import GrupoProdutos from '../pages/GrupoProdutos';
import LinhaProdutos from '../pages/LinhaProdutos';
import Situacao from '../pages/Situacao';
import SubgrupoProdutos from '../pages/SubgrupoProdutos';
import TipoCliente from '../pages/TipoCliente';
import UnidadeMedida from '../pages/UnidadesMedida';
import RegClassificacaoContabil from '../pages/RegClassificacaoContabil';
import RegGrupoProdutos from '../pages/RegGrupoProdutos';
import RegLinhaProdutos from '../pages/RegLinhaProdutos';
import RegSituacao from '../pages/RegSituacao';
import RegSubgrupoProdutos from '../pages/RegSubgrupoProdutos';
import RegTipoCliente from '../pages/RegTipoCliente';
import RegUnidadeMedida from '../pages/RegUnidadesMedida'
import ClassificacaoFiscal from '../pages/ClassificacaoFiscal';
import RegClassificacaoFiscal from '../pages/RegClassificacaoFiscal';
import TipoProduto from '../pages/TipoProduto';
import RegTipoProduto from '../pages/RegTipoProduto';
import Clientes from '../pages/Clientes';
import RegClientes from '../pages/RegClientes';
import Produtos from '../pages/Produtos';
import RegProdutos from '../pages/RegProdutos';
import RegLeitura from '../pages/RegLeitura';
import RegLeituraTeste from '../pages/RegLeituraTeste';
import Caixas from '../pages/Caixas';
import RegCaixas from '../pages/RegCaixas';
import RegTipoCaixa from '../pages/RegTipoCaixa';
import TipoCaixa from '../pages/TipoCaixa';
import RegLocalCliente from '../pages/RegLocalCliente';
import LocalCliente from '../pages/LocalCliente';
import Leituras from '../pages/Leituras';
import CondPag from '../pages/CondPag';
import RegCondPag from '../pages/RegCondPag';
import Transportadora from '../pages/Transportadora';
import RegTransportadora from '../pages/RegTransportadora';
import TipoFrete from '../pages/TipoFrete';
import RegTipoFrete from '../pages/RegTipoFrete';
import RegClienteProduto from '../pages/RegClienteProduto';
import NaturezaOperacao from '../pages/NaturezaOperacao';
import RegLote from '../pages/RegLote';
import RegNaturezaOperacao from '../pages/RegNaturezaOperacao';
import Intervalo_Acionamento from '../pages/Intervalo_Acionamento';
import RegIntervalo_Acionamento from '../pages/RegIntervalo_Acionamento';



export const routes = [
    {
        path: '/forgotPw',
        component: ForgotPw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/changePw',
        component: ChangePw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/login',
        component: Login,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },
    {
        path: '/home',
        component: Home,
        protected: true,
        exact: true,
        checkPermission: false,
        register: false
    },
    {
        path: '/cidades',
        component: Cidades,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Cidades',
        type: 'gerais',
    },
    {
        path: '/cidades/registro',
        component: RegCidades,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/estados',
        component: Estados,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Estados',
        type: 'gerais',
    },
    {
        path: '/estados/registro',
        component: RegEstados,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/usuarios',
        component: Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Usuários'
    },
    {
        path: '/usuarios/registro',
        component: RegUsuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/grupo_usuarios',
        component: Grupo_Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Grupos de Usuários'
    },
    {
        path: '/grupo_usuarios/registro',
        component: RegGrupo_Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/classificacao_contabil',
        component: ClassificacaoContabil,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Classificação Contábil',
        type: 'auxiliares',
    },
    {
        path: '/classificacao_contabil/registro',
        component: RegClassificacaoContabil,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/classificacao_fiscal',
        component: ClassificacaoFiscal,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Classificação Fiscal',
        type: 'auxiliares',
    },
    {
        path: '/classificacao_fiscal/registro',
        component: RegClassificacaoFiscal,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/cond_pag',
        component: CondPag,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Condição de Pagamento',
        type: 'auxiliares',
        caption: 'Condições de Pagamento',
    },
    {
        path: '/cond_pag/registro',
        component: RegCondPag,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/transportadora',
        component: Transportadora,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Transportadora',
        type: 'auxiliares',
    },
    {
        path: '/transportadora/registro',
        component: RegTransportadora,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/grupo_produtos',
        component: GrupoProdutos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Grupo de Produtos',
        type: 'auxiliares',
    },
    {
        path: '/grupo_produtos/registro',
        component: RegGrupoProdutos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/linha_produtos',
        component: LinhaProdutos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Linha de Produtos',
        type: 'auxiliares',
    },
    {
        path: '/linha_produtos/registro',
        component: RegLinhaProdutos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/situacao',
        component: Situacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Situação',
        type: 'auxiliares',
    },
    {
        path: '/situacao/registro',
        component: RegSituacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/subgrupo_produtos',
        component: SubgrupoProdutos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Subgrupo de Produtos',
        type: 'auxiliares',
    },
    {
        path: '/subgrupo_produtos/registro',
        component: RegSubgrupoProdutos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_frete',
        component: TipoFrete,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo de Frete',
        type: 'auxiliares',
    },
    {
        path: '/tipo_frete/registro',
        component: RegTipoFrete,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_cliente',
        component: TipoCliente,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo de Cliente',
        type: 'auxiliares',
    },
    {
        path: '/tipo_cliente/registro',
        component: RegTipoCliente,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_caixa',
        component: TipoCaixa,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo de Caixa',
        type: 'auxiliares',
    },
    {
        path: '/tipo_caixa/registro',
        component: RegTipoCaixa,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_produto',
        component: TipoProduto,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo de Produtos',
        type: 'auxiliares',
        caption: 'Tipo de Produto',
    },
    {
        path: '/tipo_produto/registro',
        component: RegTipoProduto,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/local_cliente',
        component: LocalCliente,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Local em Cliente',
        type: 'auxiliares',
    },
    {
        path: '/local_cliente/registro',
        component: RegLocalCliente,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/unidades_medida',
        component: UnidadeMedida,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Unidades de Medida',
        type: 'auxiliares',
    },
    {
        path: '/unidades_medida/registro',
        component: RegUnidadeMedida,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/clientes',
        component: Clientes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Clientes',
        type: 'cadastros',
        caption: 'Clientes',
    },
    {
        path: '/clientes/registro',
        component: RegClientes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/produtos',
        component: Produtos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Produtos',
        type: 'cadastros',
    },
    {
        path: '/produtos/registro',
        component: RegProdutos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/consulta-leitura',
        component: Leituras,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Leitura',
        type: 'operacional',
    },
    {
        path: '/leitura',
        component: RegLeitura,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/leituraTeste',
        component: RegLeituraTeste,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/caixas',
        component: Caixas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Caixas',
        type: 'cadastros',
        caption: 'Acionamentos',
    },
    {
        path: '/caixas/registro',
        component: RegCaixas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/cliente_produto/registro',
        component: RegClienteProduto,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/natureza_operacao',
        component: NaturezaOperacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Natureza Operacao',
        type: 'auxiliares',
        caption: 'Natureza de Operação',
    },
    {
        path: '/natureza_operacao/registro',
        component: RegNaturezaOperacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/lote/registro',
        component: RegLote,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true,
    },
    {
        path: '/intervalo_acionamento',
        component: Intervalo_Acionamento,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Intervalo de Acionamentos',
        type: 'auxiliares',
    },
    {
        path: '/intervalo_acionamento/registro',
        component: RegIntervalo_Acionamento,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    

];

export const fallbackRoute = Home

