import React from 'react';
import pckg from '../../../package.json';

import './index.css';

function FooterDelphus(props) {
    return (
        <div className="footerDelphus no-print">Desenvolvido por Delphus Software - Versão {pckg.version}</div>
    );
}

export default FooterDelphus;