import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ListGroup, Image, Col, Row } from 'react-bootstrap'
import { qrcode } from 'react-icons-kit/icomoon/qrcode'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import download from 'downloadjs'
import { upload } from 'react-icons-kit/icomoon/upload'
import FrDatePicker from '../components/FrDatePicker';
import Dialog from '@material-ui/core/Dialog';

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import ModalLeitura from '../components/ModalLeitura'
import FrSelect from '../components/FrSelect'
import { MDBContainer, MDBIframe } from "mdbreact";

import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/ResponsiveReg.css';
import './styles/Manut.css';
import './styles/ManutConsultaLeitura.css'
import moment from 'moment';



const swal = withReactContent(Swal)

function Leituras(props) {
  const [data, setData] = useState([])
  const [selected, setSelected] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [showModal, setShowModal] = useState(false)
  const [leitura, setLeitura] = useState({})
  const [ordemCompra, setOrdemCompra] = useState('')
  const [comboClientes, setComboClientes] = useState([{ value: '', display: '' }])
  const [comboProdutos, setComboProdutos] = useState([{ value: '', display: '' }])
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])
  const [comboCpg, setComboCpg] = useState([{ value: '', display: '' }])
  const [comboTra, setComboTra] = useState([{ value: '', display: '' }])
  const [comboTpf, setComboTpf] = useState([{ value: '', display: '' }])
  const [fk_cpg, setCpg] = useState(0);
  const [entrega, setEntrega] = useState(moment().format('YYYY-MM-DD'))
  const [fk_tpf, setTpf] = useState(0);
  const [fk_tra, setTra] = useState(0);
  const [showModalExtrato, setShowModalExtrato] = useState()
  const [arquivoExtrato, setArquivoExtrato] = useState(0);
  


  const auth = JSON.parse(localStorage['authtarento'] || JSON.stringify({ authenticated: false, user: 0 }))

  const [fk_usu, setUsu] = useState(auth.user);

  const columns = [
    // { id: 'pk_lei', numeric: false, label: 'pk', align: 'left', minWidth: 100 },

    { id: 'pk_lot', numeric: false, label: 'Código Lote', align: 'left', minWidth: 80 },
    { id: 'data_formatada', numeric: false, label: 'Data', align: 'left', minWidth: 100, order: 'data' },
    { id: 'nomecli', numeric: false, label: 'Cliente', align: 'left', minWidth: 250 },
    { id: 'localcli', numeric: false, label: 'Local Cliente', align: 'left', minWidth: 250 },
    { id: 'nomeslo', numeric: false, label: 'Situação', align: 'left', minWidth: 200 },
    // { id: 'data_geracao', numeric: false, label: 'Data pedido', align: 'left', minWidth: 100},
    // { id: 'nr_pedido_siger', numeric: false, label: 'Número pedido SIGER', align: 'left', minWidth: 100}

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      if (!props.frame) {
        setenableAdd(await api.getAcessos('Leituras', 'Inclusao'))
        setenableEdit(auth.grupo==1)
        console.log(auth.grupo)
        setenableDel(await api.getAcessos('Leituras', 'Exclusao'))

        setComboClientes(await api.getComboData('clientes'))
        setComboSituacao(await api.getComboData('situacao_lote'))
        setComboProdutos(await api.getComboData('produtos'))
        setComboCpg(await api.getComboData('cond_pag'))
        setComboTra(await api.getComboData('transportadora'))
        setComboTpf(await api.getComboData('tipo_frete'))

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        if (auth.grupo === 2) query.buscar = 'true'
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
      swal.close()
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      queryObject.fk_usu = auth.user
      if (Number(auth.grupo) === 2){
        queryObject.pk_sit = 1
      }
      if (auth.fk_cli > 0){
        queryObject.pk_cli = auth.fk_cli
      }
      setFiltered(true)
      if (!props.frame) {
        window.history.replaceState({ filtered: true }, 'filter', "/tarento/consulta-leitura?" + objectToQueryString(queryObject));
      }
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      console.log(filter)
      api.get('/caixa/leitura', { params: queryObject }).then(r => {
        setData(r.data)
        console.log(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/tarento/consulta-leitura');
    document.getElementById('searchForm').reset()
    setFilter({})
  }


  function escondeModal(e) {
    e.preventDefault()
    setShowModal(false)
  }

  function confirmaModal(e) {
    e.preventDefault()
    setShowModal(false)
    if ((fk_tpf) && (fk_tra) && (fk_cpg) && (entrega) && (ordemCompra)) {
      swal.fire({
        title: 'ATENÇÃO',
        text: "Confirma integração para os registros marcados?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          api.post('/caixa/integracao', { selected, fk_tpf, fk_cpg, fk_tra, entrega, fk_usu, ordemCompra }).then(r => {
            if (r.data.status.code == 200000) {
              swal.fire({
                title: 'Pedidos enviados com sucesso!',
                text: "Pedidos enviados com sucesso!",
                icon: 'success',
              }).then((result) => {
                window.location.reload()
              })
            }
            else
              swal.fire({
                title: 'Erro!',
                text: r.data.status.message,
                icon: 'warning',
              })

          })
        }
      })
    } else
      swal.fire('Campos obrigatórios!', 'Obrigatório informar data de entrega, condição de pagamento, transportador e tipo de frete!', 'warning')
  }

  const testeIntegracao = (e) => {
    api.get('/caixa/testeIntegracao').then(r => {
      
      swal.close()
    })
  }

  const integracao = (e) => {
    e.preventDefault();
    
    if (selected.length == 0) {
      swal.fire('Nenhum registro selecionado!', 'Selecione registros para geração de pedidos.', 'warning')
    } else {
      api.get('/caixa/leitura?fk_usu=1&selected='+ selected).then(r=>{
        if(r.data.length>0){
          swal.fire('Registro selecionado inválido', 'Seleção possui lote em aberto.', 'warning')
        } else {
          setShowModal(true)
        }
      })
    }
  }

 
  const handleChange = (e) => {
    const auxValues = { ...filter };

    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  const handleChangeModal = (e) => {
    if (e.target.name == 'ordemCompra')
      setOrdemCompra(e.target.value) 
    if (e.target.name == 'entrega')
      setEntrega(e.target.value)
    if (e.target.name == 'fk_cpg')
      setCpg(e.target.value)
    if (e.target.name == 'fk_tra')
      setTra(e.target.value)
    if (e.target.name == 'fk_tpf')
      setTpf(e.target.value)

  };


  function handleSubmit(e, fk_cai, pk_lei) {
    e.preventDefault();
    setShowModal(false)
    swal.fire({
      html: <FrLoading text="Cancelando leitura..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    let fk_usu = auth.user
    api.post('/caixa/cancelaLeitura', { fk_usu, fk_cai, pk_lei }).then((r) => {
      if (r.data && (r.status === 200)) {
        setTimeout(() => {
          swal.close()
          setTimeout(() => {
            swal.fire({
              title: 'Leitura cancelada!',
              text: "A leitura foi cancelada com sucesso!",
              icon: 'success',
              timer: 1500
            })
            let event = new Event('build');
            getData(event)
          }, 500)
        }, 500)
      } else {
        swal.close()
        swal.fire({
          title: 'Erro!',
          text: "O leitura não foi cancelada!",
          icon: 'warning',
        })
      }
    }).catch((e) => {
      swal.close()
      swal.fire({
        title: 'Erro!',
        text: "O leitura não foi cancelada!",
        icon: 'warning',
      })
    })
  }

  const mapItems = (item, id) => {
    return (
      // <LinkContainer to={"/floresta-real/buy-offers/register?offer=" + item.pk_lot}>
      //
      <ListGroup.Item action key={item.pk_lei} className="listGroupItem" onClick={(e) => handleShow(e, item.pk_lei)} style={{ backgroundColor: (item.fk_lsi === 3 ? 'rgba(255, 0, 0, 0.3)' : 'white') }}>
        <Row>
          <Col xs={5} md={3} style={{ alignItems: 'left', width: '40vw', maxWidth: '200px', display: 'flex', justifyContent: 'center', flexDirection: 'column', fontWeight: 'bold' }}>
            <p style={{ marginBottom: '2px', minWidth:'90px'}}>{'Caixa: ' + item.id_caixa+ '/' + item.sufixo}</p>
            <p style={{ marginBottom: '2px' }}>{'Status: ' + item.nomelsi}</p>
          </Col>
          <Col style={{ fontSize: '0.8rem', alignSelf: 'center' }}>
            <p style={{marginBottom: '2px', fontWeight: 'bold', fontSize: '0.9rem'}} >Código: {item.cod_cliente}</p>
            {item.data_cancelamento ? <p style={{ marginBottom: '2px' }}>Data Cancelamento: {item.data_cancelamento}</p> : <></>}
            <p style={{ marginBottom: '2px' }}>Data Leitura: {item.data}</p>
            <p style={{ marginBottom: '2px' }}>Responsável: {item.nome}</p>
          </Col>
        </Row>
      </ListGroup.Item>
      // </LinkContainer>
    )
  }

  const handleShow = (e, id) => {
    e.preventDefault()
    if (id) {
      swal.fire({
        html: <FrLoading text="Buscando registro..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/caixa/leitura?fk_usu=' + auth.user + '&pk_lei=' + id).then((r) => {
        if (r.data && (r.status === 200)) {
          setLeitura(r.data[0])
          setTimeout(() => {
            swal.close()
            setShowModal(true)
          }, 500)
        } else {
          swal.close()
          swal.fire({
            title: 'Erro!',
            text: "O registro não foi encontrado!",
            icon: 'warning',
          })
        }
      })
    }
  }

  let listGroupItems = data.map(mapItems)

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      setShowModal(false)
    }
  }

  const escondeModalExtrato = (e) => {
    setShowModalExtrato(false)
  }

  const mostraModalExtrato = async (e) => {
    e.preventDefault()
    if (selected.length >= 1) {
      
      // console.log(selected)
      // setArquivoExtrato('')
      // setShowModalExtrato(true)
      // swal.close()
      setArquivoExtrato(api.backendUrl +'/api/caixa/loteExtrato?pk_lot='+selected)
      setShowModalExtrato(true)
      // .then(r => {
      //   console.log(r.data)
      //   setArquivoExtrato(r.data)
      //   setShowModalExtrato(true)
      

      // })
      
    } else if (selected.length == 0) swal.fire('Nenhum registro selecionado!', 'Selecione um registro para replicar.', 'warning')
  }
  
  const registrarLeitura = (e) => {
    e.preventDefault();
    if (selected.length === 1) {
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/caixa/lote?pk_lot='+selected[0]).then(r => {
        console.log(r.data[0])
        
        localStorage['lote'] =  JSON.stringify({ fk_loc: r.data[0].fk_loc, fk_lot: selected[0], fk_slo: r.data[0].fk_slo });
        window.location.href = '/tarento/leitura'  
        swal.close()

      })
      
    } else if (selected.length > 0) swal.fire('Mais de um registro selecionado!', 'Selecione somente um registro por vez para replicar.', 'warning')
    else swal.fire('Nenhum registro selecionado!', 'Selecione um registro para replicar.', 'warning')
  }

  return (
    <>

      <ModalLeitura
        open={showModal}
        onClose={handleClose}
        onConfirm={handleSubmit}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        item={leitura}
        consulta
      />
      {Number(auth.grupo) === 'xxxx' ? (
      
        <div className="responsiveReg">
          <div className="responsiveHeader">
            <h3>Consulta de Leituras</h3>
            <UserOptions />
          </div>
          <div className="responsiveRegContent">
            <Link to="/tarento/leitura">
              <button className="responsiveButton" >
                <Icon icon={ic_add_box} size={18} />
                Nova Leitura
              </button>
            </Link>
            <form id="searchForm" onSubmit={getData}>
            </form>
            <div className="containerListContainers">
              <div className="itemsDiv">
                <div className="listGroupItems">
                  <ListGroup>
                    {listGroupItems}
                  </ListGroup>
                </div>
              </div>
            </div>
            <FooterDelphus />
          </div>
        </div>
      ) : (
        <div className="Manut">
          <NavBar />
          <div className={"ManutContent"}>
            <div className="defaultHeader">
              <h3>Consulta de Leituras</h3>
              <UserOptions />
            </div>
            <div className="filtersDiv">
              <form id="searchForm" onSubmit={getData}>
                <div className='filterInputDiv'>
                  <div className='periodoDiv'>
                    <div className='datePicker'>
                      <FrDatePicker
                        value={filter.data_ini}
                        name="dataIni"
                        id="dataIni"
                        color="#528b46"
                        label="Data Inicial"
                        variant="outlined"
                        size="small"
                        style={{ width: '100%' }}
                        unclear={true}
                        onChange={handleChange}
                      />
                    </div>

                    <div className='datePicker'>
                      <FrDatePicker
                        value={filter.data_fim}
                        name="dataFim"
                        id="dataFim"
                        color="#528b46"
                        label="Data Final"
                        variant="outlined"
                        size="small"
                        style={{ width: '100%' }}
                        unclear={true}
                        onChange={handleChange}
                      />

                    </div>

                  </div>

                  <div className='textInput'>
                    <FrTextInput
                      // 
                      value={filter.fk_lot || ''}
                      style={{ width: '100%' }}
                      name="fk_lot"
                      id="fk_lot"
                      color="#333333"
                      label="Lote"
                      variant="outlined"
                      size="small"
                      onChange={handleChange}
                    />

                  </div>
                  <div className='select' style={auth.fk_cli>0? {height: 0, overflow: 'hidden'} : {}}>
                    <FrSelect
                      // 
                      value={filter.pk_cli || ''}
                      style={{ width: '100%' }}
                      name="pk_cli"
                      id="pk_cli"
                      // disabled={auth.fk_cli!= '0'}
                      label='Cliente'
                      data={comboClientes}
                      onChange={handleChange}
                      // hidden={auth.fk_cli>0}
                    />

                  </div>
                  {/* <FrSelect
                    value={filter.pk_pro || ''}
                    style={{ width: 230 }}
                    name="pk_pro"
                    id="pk_pro"
                    label='Produto'
                    data={comboProdutos}
                    onChange={handleChange}
                  /> */}
                  <div className='select' >

                    <FrSelect
                      // 
                      value={filter.pk_sit || ''}
                      style={{ width: '100%' }}
                      name="pk_sit"
                      id="pk_sit"
                      label='Situação'
                      data={comboSituacao}
                      onChange={handleChange}
                      // hidden={auth.fk_cli>0}
                    />
                  </div>

                  <div className='textInput'>
                    <FrTextInput
                      // 
                      value={filter.nr_pedido_siger || ''}
                      style={{ width: '100%' }}
                      name="nr_pedido_siger"
                      id="nr_pedido_siger"
                      color="#333333"
                      label="Nr. Pedido SIGER"
                      variant="outlined"
                      size="small"
                      onChange={handleChange}
                    />
                  </div>

                </div>
                <div className='divButtons'>
                  <button onClick={getData}>
                    <Icon icon={ic_search} size={18} />
                    Pesquisar
                  </button>
                  <button onClick={clearForm}>
                    <Icon icon={ic_delete} size={18} />
                    Limpar
                  </button>
                  {(enableAdd !== 'S') ? '' :
                    <Link to="/tarento/leitura">
                      <button >
                        <Icon icon={ic_add_box} size={18} />
                        Novo Registro
                      </button>
                    </Link>}
                  {auth.gera_pedido == 'S'?
                  <>
                    <button onClick={integracao}>
                      <Icon icon={upload} size={18} />
                      Gerar Pedido
                    </button>

                  </>
                  :
                    <></>}

                  <button onClick={registrarLeitura}>
                    <Icon icon={upload} size={18} />
                    Registrar Leitura
                  </button>
                  <button onClick={(e) => mostraModalExtrato(e)}>
                    Extrato
                  </button>
                  
{/* 
                  <button onClick={testeIntegracao}>
                    <Icon icon={upload} size={18} />
                    Testar integracao
                  </button> */}

                </div>

                <div>
                 
                  <Dialog aria-labelledby="simple-dialog-title" open={showModalExtrato} fullWidth={true} maxWidth={'lg'}>
                    <div className="FrModalFooter">
                      <div className="FrModalButtonsDiv">
                        <MDBContainer className="text-center">
                          <MDBIframe src={arquivoExtrato} />
                        </MDBContainer>
                        <button onClick={escondeModalExtrato}> Sair </button>
                      </div>
                    </div>
                  </Dialog>
                </div>
                <div>
                  <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={false} maxWidth={'lg'} >
                    <div style={{ padding: '20px', paddingTop: '40px' }}>

                      <div className="defaultHeader">

                        <h3>Integração Siger</h3>
                      </div>
                      <br />


                      <div style={{ display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column', alignItems: 'flex-start' }}>

                        <FrDatePicker
                          name="entrega"
                          id="entrega"
                          color="#528b46"
                          label="Data de Entrega"
                          variant="outlined"
                          size="small"
                          style={{ width: 190 }}
                          unclear={true}
                          value={entrega}
                          onChange={handleChangeModal}
                        />

                        <FrTextInput
                          maxLength='45'
                          value={ordemCompra || ''}
                          onChange={handleChangeModal}
                          name="ordemCompra"
                          id="ordemCompra"
                          color="#333333"
                          label="Ordem Compra"
                          variant="outlined"
                          size="small"
                          required
                          style={{ width: 300 }}
                        />

                        <FrSelect
                          style={{ width: 300 }}
                          name="fk_cpg"
                          id="fk_cpg"
                          label='Condição de Pagamento'
                          data={comboCpg}
                          value={fk_cpg}
                          onChange={handleChangeModal}
                        />

                        <FrSelect
                          style={{ width: 300 }}
                          name="fk_tra"
                          id="fk_tra"
                          label='Transportador'
                          data={comboTra}
                          value={fk_tra}
                          onChange={handleChangeModal}
                        />

                        <FrSelect
                          style={{ width: 150 }}
                          name="fk_tpf"
                          id="fk_tpf"
                          label='Tipo de Frete'
                          data={comboTpf}
                          value={fk_tpf}
                          onChange={handleChangeModal}

                        />

                      </div>
                    </div>
                    <div className="FrModalFooter">
                      <div className="FrModalButtonsDiv" >
                        <button onClick={confirmaModal}> Confirmar </button>
                        <button onClick={escondeModal}> Sair </button>
                      </div>
                    </div>
                  </Dialog>
                </div>

              </form>
            </div>
            <br />



            <div className={"tableDiv" + (props.frame && data.length > 0 ? '-frame' : '')} style={{overflowX: 'auto'}}>
              <FrTable detail={true} regName="leituras" frame={'caixa'} columns={columns} searched={filtered} codeName="pk_lot" page={'Leituras'} data={data} enableEdit={enableEdit} enableDel={enableDel} selected={selected} setSelected={setSelected} readOnly />
            </div>
            {props.frame ? (<div>

            </div>) : <FooterDelphus />}

          </div>
        </div>
      )}
    </>
  );
}

export default Leituras;
