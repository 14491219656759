import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {ic_close} from 'react-icons-kit/md/ic_close'
import {ic_check} from 'react-icons-kit/md/ic_check'
import {back} from 'react-icons-kit/entypo/back'
import {ic_delete} from 'react-icons-kit/md/ic_delete'
import { Icon } from 'react-icons-kit'

import './index.css'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column'
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEscapeKeyDown
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Código de Leitura: {props?.item?.pk_cai}</h2>
            <p id="transition-modal-description">Id. Caixa: {props?.item?.id_caixa}</p>
            <p id="transition-modal-description">Código: {props?.item?.cod_cliente}</p>
            <div className="modalButtonsWrapper">
                {props?.item?.fk_lsi !== 3 ? (<><button className="modalConfirmButton" onClick={e => props.onConfirm(e, props?.item?.pk_cai, (props.consulta ? props?.item?.pk_lei : null))}><Icon icon={props.consulta ? ic_delete : ic_check} size={18}/>{props.consulta ? 'Cancelar' : 'Confirmar'}</button></>) : (<></>)}
                <button className="modalConfirmButton" onClick={e => props.onClose(e, 'button')}><Icon icon={props.consulta ? back : ic_close} size={18}/>{props.consulta ? 'Voltar' : 'Cancelar'}</button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}