import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import { ic_search } from 'react-icons-kit/md/ic_search'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import api from '../services/api'
import { paramsToObject, getUserCode, formToObject, objectToQueryString } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrTableReg from "../components/FrTableReg";
import { Icon } from "react-icons-kit";
import { ic_add_box } from "react-icons-kit/md/ic_add_box";
import Dialog from '@material-ui/core/Dialog';
import { MDBContainer, MDBIframe } from "mdbreact";


const swal = withReactContent(Swal)

function ClienteProduto() {
  const [dataPro, setDataPro] = useState([{ display: '' }])
  const [updateType, setUpdateType] = useState(false)
  const [comboPro, setComboPro] = useState([{ value: '', display: '' }])
  const [comboUnd, setComboUnd] = useState([{ value: '', display: '' }])
  const [nomecli, setNomeCli] = useState('')
  const [fk_cli, setfk_cli] = useState(0)
  const [fk_pro, setfk_pro] = useState(0)
  const [fk_und, setfk_und] = useState(0)
  const [nr_oc, setnr_oc] = useState(0);
  const [codigo, setCodigo] = useState(0)
  const [edi, setEdi] = useState(0)
  const [buscaEdi, setBuscaEdi] = useState(0)
  const [buscaUnidade, setBuscaUnidade] = useState(0)
  const [buscaPro, setBuscaPro] = useState(0)
  const [preco, setPreco] = useState(0)
  const [ordenacao, setOrdenacao] = useState(0)
  const [nomepro, setNomePro] = useState('')
  const [page, setPage] = useState(0)
  const [modal, setModal] = useState(false)
  const [updatemodal, setUpdateModal] = useState(false);
  const [pkModal, setPkModal] = useState(0);
  const [fator, setFator] = useState(0);

  const columnsPro = [
    { id: 'nomepro', numeric: false, label: 'Produto', align: 'left', minWidth: 300 },
    { id: 'preco', numeric: false, label: 'Preço', align: 'left', minWidth: 300 },
    { id: 'ordenacao', numeric: false, label: 'Ordenação', align: 'left', minWidth: 300 },
    { id: 'edi', numeric: false, label: 'EDI', align: 'left', minWidth: 300 },
    { id: 'sigla', numeric: false, label: 'Unidade Medida', align: 'left', minWidth: 300 },

  ]


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setCodigo(query.codigo)

      setComboPro(await api.getComboData('produtos'))
      setComboUnd(await api.getComboData('unidades_medida'))

      if (query.codigo > 0) {
        setfk_cli(query.codigo)
        setUpdateType(true)
        api.get('/clientes?pk_cli=' + query.codigo).then(r => {
          setNomeCli(r.data[0].razao_social)
        })
        
        api.get('/cliente_produto?fk_cli=' + query.codigo).then(r => {
          if (r.data.length > 0) {
            setDataPro(r.data);
            console.log(r.data)

          }
          swal.close()

        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })


    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...dataPro };
        auxValues.usuario = getUserCode()
        auxValues.fk_cli = fk_cli
        auxValues.data = dataPro
        let errors = consistData(auxValues)
        if (errors.length === 0) {
          try {
            api.post('/cliente_produto/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          } catch {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi adicionado!",
              icon: 'warning',
            })

          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    window.history.back()


    // swal.fire({
    //   title: 'ATENÇÃO',
    //   text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Sim',
    //   cancelButtonText: 'Não'
    // }).then((result) => {
    //   if (result.value) {
    //     window.history.back()
    //   }
    // })
  }

  const getData = (e) => {
    e.preventDefault()
    // var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = {}
    // if (!isEmpty(queryObject)) {
    if (true) {
      if (buscaEdi != ''){
        queryObject.edi = buscaEdi
      }
      if(buscaUnidade > 0){
        queryObject.unidade = buscaUnidade
      }
      if (buscaPro > 0){
        queryObject.produto = buscaPro      
      }


      window.history.replaceState({ filtered: true }, 'filter', "/tarento/cliente_produto/registro?codigo=" +codigo+ (objectToQueryString(queryObject).length > 0 ? '&'+ objectToQueryString(queryObject) : ''));

      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/cliente_produto?fk_cli='+codigo, { params: queryObject }).then(r => {
        setDataPro(r.data);
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  useEffect(() => {
    const fetchPro = async () => {
      if (fk_pro > 0) {
        api.get("/produtos?pk_pro=" + fk_pro).then((r) => {
          if (r.data[0]) {
            setNomePro(r.data[0].descricao);
          }
        });
      } else setNomePro("");
    };

    fetchPro();
  }, [fk_pro]);

  function mostraModal(e) {
    e.preventDefault();
    setfk_pro(null);
    setPreco(null);
    setNomePro(null);
    setModal(true);
    setnr_oc(null);
    setfk_und(null);
    setEdi(null);
    setFator(null);
    setUpdateModal(false)
    setPkModal(0)
    api.get('/cliente_produto?ultimo=true&fk_cli='+fk_cli).then(r => {
      if (r.data){
        setOrdenacao(Number(r.data[0].ordenacao)+1)
      }
    })
  }

  function closeModal(e) {
    e.preventDefault();
    setModal(false);
  }


  function handleSubmitModal(e) {
    e.preventDefault();
    if ((!fk_pro) || (!ordenacao) || (!preco)|| (!fk_und) || (!edi)|| (!fator)) {
      swal.fire({
        title: "Erro!",
        text: "Informe todos os campos!",
        icon: "warning",
      });
    } else
      if (!updatemodal) {
        api.get('/cliente_produto?fk_cli='+ fk_cli+ '&edi='+edi ).then(r=>{
          console.log(!r.data)
          console.log(r.data)
          console.log(r.data.length)
          if (r.data.length == 0){
            incluiPro(e);

          } else {
            swal.fire({
              title: "Erro!",
              text: "EDI já informado para este cliente!",
              icon: "warning",
            });
          }
        })
      }
      else updatePro(e);
    closeModal(e);
  }


  const handleChangeModal = (e) => {
    if (e.target.name == "fk_pro") setfk_pro(e.target.value);
    if (e.target.name == "preco") setPreco(e.target.value);
    if (e.target.name == "ordenacao") setOrdenacao(e.target.value);
    if (e.target.name == "edi") setEdi(e.target.value);
    if (e.target.name == "fk_und") setfk_und(e.target.value);
    if (e.target.name == "nr_oc") setnr_oc(e.target.value);
    if (e.target.name == "fator") setFator(e.target.value);
  };

  function incluiPro(e) {
    e.preventDefault();
    let pk = 1;
    let duplicidade = false;
    if (dataPro[0].nomepro !== "") {
      var i;
      for (i = 0; i < dataPro.length; i++) {
        if (
          (fk_pro > 0 && dataPro[i].fk_pro == fk_pro)
        ) {
          duplicidade = true;
        }
      }
    }

    if (!duplicidade) {
      let usuario = getUserCode()
      api.post('/cliente_produto/add', { fk_cli, ordenacao, fk_pro, preco, usuario, edi, fk_und, nr_oc, fator  }).then(r => {
        if (r.status === 200) {
          swal.fire({
            title: 'Registro Adicionado',
            text: "O registro foi adicionado com sucesso!",
            icon: 'success',
          }).then((result) => {
            if (result.value) {
              api.get('/cliente_produto?fk_cli=' + fk_cli).then(r => {
                if (r.data.length > 0) {
                  setDataPro(r.data);
                }
              })
            }
          })
        }
      })
    } else
      swal.fire({
        title: "Erro!",
        text: "Produto já lançado! Não é permitido lançar em duplicidade!",
        icon: "warning",
      });

  }


  function updatePro(e) {
    e.preventDefault();

    let item = [];
    let i;

    let duplicidade = false;
    for (i = 0; i < dataPro.length; i++) {
      if (
        dataPro[i].pk !== pkModal &&
        (fk_pro > 0 && dataPro[i].fk_pro == fk_pro)
      ) {
        duplicidade = true;
      }
    }

    if (!duplicidade) {
      let usuario = getUserCode()
      api.post('/cliente_produto/edit', { fk_cli, ordenacao, fk_pro, preco, usuario, pkModal, edi, fk_und, nr_oc, fator }).then(r => {
        if (r.status === 200) {
          swal.fire({
            title: 'Registro Alterado',
            text: "O registro foi alterado com sucesso!",
            icon: 'success',
          }).then((result) => {
            if (result.value) {
              api.get('/cliente_produto?fk_cli=' + fk_cli).then(r => {
                if (r.data.length > 0) {
                  setDataPro(r.data);
                }
              })
            }
          })
        }
      })
    } else
      swal.fire({
        title: "Erro!",
        text: "Produto já lançado! Não é permitido lançar em duplicidade!",
        icon: "warning",
      });

  }


  console.log('teste')

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'buscaEdi': 
        setBuscaEdi(e.target.value)
        break

      case 'buscaPro': 
        setBuscaPro(e.target.value)
        break
        

      case 'buscaUni':
        setBuscaUnidade(e.target.value)
        break

      

      // case 'fk_cli': setFk_cli(e.target.value)
    }
  };

  console.log(page, '----------------')

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Produtos do Cliente</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='45'
            value={nomecli || ''}
            color="#49573B"
            label="Cliente"
            variant="outlined"
            size="small"
            disabled
            style={{ width: 320 }}
          />

          <FrSelect
            value={buscaPro|| ''}
            style={{'&.MuiInputLabel-shrink' : {
              padding: '5px',
              top: '0',
              color: '#333333'
            }, width: 330  }}
            name="buscaPro"
            id="buscaPro"
            label='Produto'
            data={comboPro}
            onChange={handleChange}
            

          />

          <FrTextInput
            maxLength='45'
            value={buscaEdi || ''}
            color="#49573B"
            label="EDI"
            name="buscaEdi"
            id="buscaEdi"
            variant="outlined"
            size="small"
            style={{ width: 320 }}
            onChange={handleChange}
          />
          
          <FrSelect
            value={buscaUnidade|| ''}
            style={{'&.MuiInputLabel-shrink' : {
              padding: '5px',
              top: '0',
              color: '#333333'
            }, width: 330  }}
            name="buscaUni"
            id="buscaUni"
            label='Unidade'
            data={comboUnd}
            onChange={handleChange}
            

          />

          <button onClick={getData}>
            <Icon icon={ic_search} size={18}/>
            Pesquisar
          </button>

          <div className="grupoTitulo">Produtos</div>
          <div className="grupoEdits">
            <button onClick={(e) => mostraModal(e)}>
              <Icon icon={ic_add_box} size={18} />
              Incluir
            </button>

            <div >
              <FrTableReg setPkModal={setPkModal}
                setUpdateModal={setUpdateModal}
                setModal={setModal}
                setOrdenacao={setOrdenacao}
                setPreco={setPreco}
                setfk_pro={setfk_pro}
                setfk_und={setfk_und}
                setEdi={setEdi}
                setNomePro={setNomePro}
                setFator={setFator}
                editar={true}
                setDataGrid={setDataPro}
                detail regName="cliente_produto"
                columns={columnsPro}
                searched={false}
                setPage={setPage}
                codeName="display"
                page={page}
                data={dataPro}
              />



              <div>
                <Dialog aria-labelledby="simple-dialog-title" open={modal} fullWidth={false} maxWidth={'lg'}>
                  <div className="FrModalFooter">
                    <div className="FrModalButtonsDiv">
                      <MDBContainer className="text-center">

                        <div className="fields">
                          <FrSelect
                            value={fk_pro}
                            onChange={handleChangeModal}
                            name="fk_pro"
                            id="fk_pro"
                            label="Produto"
                            data={comboPro}
                            style={{ width: 300 }}
                          />

                          <FrTextInput
                            maxLength="10"
                            value={preco || ""}
                            onChange={handleChangeModal}
                            name="preco"
                            id="preco"
                            color="#528b46"
                            label="Preço"
                            variant="outlined"
                            size="small"
                            style={{ width: 132 }}
                          />

                          <FrTextInput
                            maxLength="10"
                            value={ordenacao || ""}
                            onChange={handleChangeModal}
                            name="ordenacao"
                            id="ordenacao"
                            color="#528b46"
                            label="Ordenação"
                            variant="outlined"
                            size="small"
                            style={{ width: 132 }}
                          />
                          <FrTextInput
                            maxLength="10"
                            value={edi || ""}
                            onChange={handleChangeModal}
                            name="edi"
                            id="edi"
                            color="#528b46"
                            label="EDI"
                            variant="outlined"
                            size="small"
                            style={{ width: 152 }}
                          />
                          <FrSelect
                            value={fk_und}
                            onChange={handleChangeModal}
                            name="fk_und"
                            id="fk_und"
                            label="Unidade"
                            data={comboUnd}
                            style={{ width: 180 }}
                          />
                          <FrTextInput
                            maxLength="10"
                            value={nr_oc || ""}
                            onChange={handleChangeModal}
                            name="nr_oc"
                            id="nr_oc"
                            color="#528b46"
                            label="Número OC"
                            variant="outlined"
                            size="small"
                            style={{ width: 200 }}
                          />
                          <FrTextInput
                            maxLength="10"
                            value={fator || ""}
                            onChange={handleChangeModal}
                            name="fator"
                            id="fator"
                            color="#528b46"
                            label="Fator"
                            variant="outlined"
                            size="small"
                            style={{ width: 150 }}
                          />
                        </div>
                      </MDBContainer>
                      <button onClick={handleSubmitModal}>Salvar</button>
                      <button onClick={closeModal}>Cancelar</button>
                    </div>
                  </div>
                </Dialog>
              </div>

            </div>
          </div>

        </form>
        <div className="regButtonsDiv">
          {/* <button onClick={handleSubmit}>Salvar</button> */}
          <button onClick={handleCancel}>Sair</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default ClienteProduto;
