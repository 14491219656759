import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav'
import NavBar from '../components/NavBar'
import { Icon } from 'react-icons-kit'
import { iosPeople } from 'react-icons-kit/ionicons/iosPeople'
import { iosPerson } from 'react-icons-kit/ionicons/iosPerson'
import { ic_work } from 'react-icons-kit/md/ic_work'
import { qrScanner } from 'react-icons-kit/ionicons/qrScanner'
import { iosBox } from 'react-icons-kit/ionicons/iosBox'
import { filter } from 'react-icons-kit/fa/filter'
import { ic_launch } from 'react-icons-kit/md/ic_launch'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Tooltip from '@material-ui/core/Tooltip';
import UserOptions from '../components/UserOptions'
import './styles/Home.css';
import FooterDelphus from '../components/FooterDelphus';
import api from '../services/api'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Home() {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authtarento'] || JSON.stringify({ authenticated: false, user: 0 })));

  useEffect(() => {
    if (auth.grupo === 2) window.location.href = '/tarento/leitura'
  }, [])

  const controles = (item, index) => {
    return (
      <Nav.Link href={"/tarento/" + String(item.origem).toLowerCase() + '/registro?codigo=' + Number(item.cod_origem)} target="_blank" className="atalhoControle">
        <Icon icon={ic_launch} size={20} />
        <strong>{'[' + item.origem + '] ' + item.modelo + ' - ' + item.tipo + ' (Prazo Limite: ' + item.prazo_limite_formatado + ')'}</strong>
      </Nav.Link>
    )
  }

  return (
    <div className="Home">
      <NavBar />
      <div className="HomeContent">
        <div className="defaultHeader">
          <h3>Seja bem vindo(a), <strong>{auth.name}</strong>. Seu último login foi em {auth.ultimo_login}.</h3>
          <UserOptions />
        </div>
        <br />

        <div className="atalhos">
          <Tooltip title="Segure Ctrl para abrir em nova aba">
            <Nav.Link href="/tarento/clientes" className="atalho">
              <Icon icon={ic_work} size={40} />
              <strong>{'Clientes'}</strong>
            </Nav.Link>
          </Tooltip>
          <Tooltip title="Segure Ctrl para abrir em nova aba">
            <Nav.Link href="/tarento/produtos" className="atalho">
              <Icon icon={filter} size={40} />
              <strong>{'Produtos'}</strong>
            </Nav.Link>
          </Tooltip>
          <Tooltip title="Segure Ctrl para abrir em nova aba">
            <Nav.Link href="/tarento/caixas" className="atalho" onClick={localStorage['lock_cli'] =  JSON.stringify({ lock_cli: 'N' })}>
              <Icon icon={iosBox} size={40} />
              <strong>{'Acionamentos'}</strong>
            </Nav.Link>
          </Tooltip>
          <Tooltip title="Segure Ctrl para abrir em nova aba">
            <Nav.Link href="/tarento/leitura" className="atalho">
              <Icon icon={qrScanner} size={30} />
              <strong>{'Leitura'}</strong>
            </Nav.Link>
          </Tooltip>






        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Home;
